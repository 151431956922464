import { useEffect, useState } from "react";
import { Outlet, Link } from "react-router-dom";
import Header from "./Header";
import Sidebar from "./Sidebar";
import Title from "./Title";
import useAuth from "../hooks/useAuth";

const Layout = ({title, documentTitle, children, active, buttons}) => {
  const auth = useAuth();
  
  

  const [sidebar, setSidebar] = useState(true);
  useEffect(()=>{
    document.title = `${documentTitle??title} - Mindmill IO`;
    setSidebar(false);
  }, [title,documentTitle]);

  const toggleSidebar=()=>{
    setSidebar(!sidebar)
  }
  
  return (
    <>
    <Sidebar userInfo={auth} isOpen={sidebar} active={active} onSidebarToggle={toggleSidebar}/>
      <div className="w-screen space-y-6 flex-1 flex flex-col lg:pl-80 rtl:lg:pl-0 rtl:lg:pr-80">
        <Header onSidebarToggle={toggleSidebar}/>
        <main className="flex-1 w-full px-4 mx-auto md:px-6 lg:px-8  max-w-7xl">
        <Title buttons={buttons} title={title}>
          {children}
        </Title>

            

            
        </main>
        <footer className="py-4 shrink-0">

        </footer>
      </div>
      
    </>
  )
};

export default Layout;