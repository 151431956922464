import axios from 'axios';
import Cookies from 'js-cookie';

const axiosClient = axios.create({
    baseURL: process.env.REACT_APP_REST_URL,
    headers: {
        "Authorization": "Bearer "+Cookies.get("mm-customer-portal-token"),
        "Access-Control-Allow-Origin": `https://${window.location.host}`,
        "Access-Control-Allow-Headers": "Content-Type, Authorization",
        "Access-Control-Allow-Methods": "GET, POST",
        'Access-Control-Allow-Credentials': "true",

    }
 });

 export default axiosClient;
