import { useNavigate, useParams } from "react-router-dom";
import { EditCard, EditColumn, EditGroup, EditLayout, EditTextField, GridLayout } from "../components/Edit"
import { sendNotification } from "../components/Notification";
import useRest from "../hooks/useRest";
import { useEffect, useState } from "react";
import useData from "../hooks/useData";

const CreateLink = ({})=>{
    return <LinkForm/>
}
const EditLink = ({})=>{
    const { id } = useParams();

   
    return <LinkForm id={id}/>
}
const LinkForm = ({id}) =>{
    const {get,post, put} = useRest("links");
    const [links, updateLinks] = useData([],"links", false, (data)=>{
        let items = {
            "Legal":true,
            "Strategy": true,
            "Reports": true,
            "Content (Final)": true,
            "Content (Review)": true,
            "Video (Final)": true,
            "Video (Review)": true
        }
        data.forEach(element => {
            items[element.tag]=true;
        });
        return Object.keys(items);
    });
    const [link, setLink] = useState({});
    const [error, setError] = useState();
    let navigate = useNavigate(); 
    useEffect(()=>{
        if(error){
            sendNotification("danger", "Something went wrong");
        }
    }, [error])
    useEffect(()=>{
        if(id){
            get(`/${id}`).then(response=>{
                setError(null);

                setLink(response.data);
            }).catch(setError)
        }
    }, [id])
    const handleSubmit = (data)=>{
        let method = id?put:post;
        if(id){
            data.value.id=parseInt(id);
        }
        method(data.value).then(response=>{
            setError(null);
            sendNotification("success", "Saved");
            
            switch(data.action){
                case "another":
                    window.location.reload(false);
                    return navigate(`../links/create`)
                default:
                    return navigate(`../links/`)

            }
        }).catch(setError);
    }
    return <EditLayout create={!id} onSubmit={handleSubmit}>
        <EditColumn span="2">
            <EditGroup label="Link">
                <EditCard columns="1">
                    {id&&<input type="hidden" id="data.id" name="id"/>}
                    <GridLayout columns="2">
                    <EditTextField value={link.title} name="title" label="Title"/>
                    <EditTextField value={link.tag} name="tag" label="Folder" suggestions={links}/>
                    </GridLayout>
                    <EditTextField value={link.description} name="description" label="Description"/>
                    <EditTextField value={link.url} name="url" label="Url"/>
                    
                </EditCard>
            </EditGroup>
            </EditColumn>
    </EditLayout>
}

export {CreateLink, EditLink};