import useData from "../hooks/useData";
import Filter from "../components/Filter";
import { DataTable } from "../components/DataTable";
import MultiSelect from "../components/form/MultiSelect";
import { useEffect, useState } from "react";
import { MultiDimTable } from "../components/MultiDimensionalDataTable";
import useRest from "../hooks/useRest";
import moment from "moment";
import X from "../components/svg/X";
import useAuth from "../hooks/useAuth";
import RichText from "../components/form/RichText";
import { SArrowRight } from "../components/svg/Arrow";
import { sendNotification } from "../components/Notification";
import Trash from "../components/svg/Trash";
import Document from "../components/svg/Document";
import axiosClient from "../axiosClient";
import ConfirmationButton from "../components/ConfirmationButton";
import Modal from "../components/Modal";
import DocumentAdd from "../components/svg/DocumentAdd";
import DateFormat from "../components/DateFormat";
import { useSearchParams } from "react-router-dom";


const DateRow = (value)=>{
    if(!value.liveDate){
        return <></>;
    }
    let date = new Date(value.liveDate);
    const dateOptions = {
        day: 'numeric',
        month:'long',
        year:'numeric'
    }
    
    let dateString = date.toLocaleDateString('en-US', dateOptions);
    if(value.status=="Awaiting feedback"){
        return <span className="text-primary-400">{dateString}</span>
    }
    return <>{dateString}</>
}
const Status = (status) => {
    if(!status){
        return <></>
    }
    const className = 'inline-flex items-center justify-center h-6 xl:px-2 xl:py-0.5 w-6 xl:w-auto overflow-hidden whitespace-nowrap text-sm font-medium tracking-tight rounded-xl ';
    
    switch(status){
        case 'Completed':
            return <span className={`${className}  text-gray-400 bg-gray-400/10`}><span className="hidden xl:inline">{status}</span><div className="xl:hidden bg-gray-400 h-3 w-3 rounded-full"/></span>;

        case 'In production':
            return <span className={`${className} text-blue-400 bg-blue-400/10`}><span className="hidden xl:inline">{status}</span><div className="xl:hidden bg-blue-400 h-3 w-3 rounded-full"/></span>;
        case 'Awaiting feedback':
            return <span className={`${className} text-primary-400 bg-primary-400/10`}><span className="hidden xl:inline">{status}</span><div className="xl:hidden bg-primary-400 h-3 w-3 rounded-full"/></span>;
        case 'Live':
        
             return <span className={`${className} text-success-400 bg-success-400/10`}><span className="hidden xl:inline">{status}</span><div className="xl:hidden bg-success-400 h-3 w-3 rounded-full"/></span>
            
             default:
                return <>{status}</>
    }
    
 
}
let setStatus = (status) => {

};
let currentStatus = [];

let statusCallback = () =>{

}
const StatusFilter = ({onChange, value})=>{
    useEffect(()=>{
        currentStatus=value;
        statusCallback(value);
    }, [value])
    setStatus=(status)=>{
        onChange(status)
        statusCallback(value);
        
    };
    return <MultiSelect value={value} onChange={onChange} placeholder="All">
        <option value="Awaiting feedback">Awaiting feedback</option>
        <option value="In production">In production</option>
        <option value="Live">Live</option>
        <option value="Completed">Completed</option>
    </MultiSelect>
}
const TypeFilter = ({onChange, value})=>{
    const [types,] = useData([], "podio/types", false);

    return <MultiSelect value={value} onChange={onChange} placeholder="All">
        {
            types.map((type,index)=>{
                
                return <option value={type} key={index}>{type==""?"None":type}</option>
            })
        }
    </MultiSelect>
}







const TaskFilter = ({onChange}) => {

    const sParams = useSearchParams();

    return <Filter searchParams={sParams} onChange={onChange} filters={[
        {
            name: "status",
            element: <StatusFilter/>,
            label: "Status",
            defaultValue: []
        },
        {
            name: "type",
            element: <TypeFilter/>,
            label: "Type",
            defaultValue: []
        }
    ]}/>
}



const Projects = () =>{
    const [projects, updateProjects, isLoading] = useData([],"podio/projects");
    
    const [userInfo,] = useAuth();
    const [attachments, setAttachments] = useState();
    const [selectedId, setSelectedId] = useState();
    const [addAttachmentCallback, setAddAttachmentCallback] = useState({method:null});
    const {get, post} = useRest("podio/projects/");
    const [text, setText] = useState();

    
    
    const addAttachment = ()=>{
        post({
            text:text
        },`${selectedId}/attachments`).then(()=>{
            updateItems();
            setText("");
            sendNotification('success', "Success!")
        }).catch(error=>{
            sendNotification("danger", "Something went wrong")
        });
    }

    const updateItems = ()=>{
        get(`${selectedId}/attachments`).then(response=>{
            setAttachments(response.data)
            if(addAttachmentCallback.method){
                addAttachmentCallback.method((response.data?.length??0)>0);
            }
        });
    }
    const deleteAttachment = (id)=>{
        axiosClient({
            url:`podio/attachments/${id}`,
            method: "DELETE"
        }).then(()=>{
            updateItems();
            
        })
    }
    useEffect(()=>{
        if(selectedId){
            updateItems();
        } else{
            setAttachments(null);
        }
    }, [selectedId])

   const ViewAttachments = (item)=>{
    const callback =(v)=>{
        item.hasAttachments=v;
    }
    const handleClick = (e)=>{
        setSelectedId(item.id);
        setAddAttachmentCallback({method:callback});
    }
    if(selectedId===item.id){
        return <></>
    }
    if(userInfo.admin){
        return <button onClick={handleClick} className={item.hasAttachments?"":"hidden group-hover:block"}>
            {
                item.hasAttachments?<Document className="h-5 w-5"/>:<DocumentAdd className="h-5 w-5"/>
            }
        </button>;
    } else{
        if(item.hasAttachments){
            return <button onClick={handleClick}>
                <Document className="h-5 w-5"/>
            </button>;
        } else{
            return <></>
        }
    }
    
    
   }
   const CloseAttachments = (e)=>setSelectedId(null);

    return <div>
        <div>
        <MultiDimTable autoExpand={(i)=>{ return i.status=="Awaiting feedback";}}  items={projects} loading={isLoading} filter={<TaskFilter/>}>
        <td field="items" nested/>
        <td field="name" header="Titel" className=" text-ellipsis overflow-hidden whitespace-nowrap"/>
        <td field="type" header="Type" className="hidden xl:block"/>
        <td field="status" header="Status" className="col-start-6 col-end-7 xl:col-start-auto xl:col-end-auto mr-4 xl:mr-0 flex flex-row-reverse pr-4 xl:pr-2 xl:flex-row" display={Status}/>
        <td header="Live / Deadline" display={DateRow} className="hidden xl:block"/>
        <td header="Links / Info" display={ViewAttachments} className="hidden xl:block"/>
        
    </MultiDimTable>
        </div>
    
    {
        attachments&&<Modal className="rounded-xl border-dark-300 border p-6 bg-dark-600 h-fit " onHide={CloseAttachments}>
            <div className="">
            <div className="flex justify-between mb-6 items-center">
                <span className="text-xl font-bold tracking-tight">
                    Links / Info
                </span>
           
                </div>
            {
                
                <ul className="flex flex-col space-y-6 text-gray-300">
                {
                    attachments.map((a,index)=><li key={index} className="  space-y-2 flex flex-col ">
                        <span className="self-end">{DateFormat(a.timestamp)}</span>
                        <div className="group border border-dark-300 rounded-lg  p-4 flex justify-between"><div className=" w-[calc(100%-28px)]">
                        <RichText readOnly value={a.text} className=" cursor-text block w-full "/>
                            </div> 
                        {
                            userInfo.admin&&<div className="px-2 hidden group-hover:block">
                            <ConfirmationButton  onClick={()=>deleteAttachment(a.id)} content="Are you sure?" title="Delete attachment" onConf>
                            <Trash className="h-3 w-3"/>
                            </ConfirmationButton>
                        </div>
                        }
                        </div>
                    </li>)
                }
            </ul>
            }
            {
                userInfo.admin&&<div className="mt-12 pt-6 space-y-2 flex flex-col">
                    <RichText placeholder="Write your comment" value={text} onChange={setText}/>

                    <button type="button" onClick={addAttachment} className="self-end inline-flex items-center justify-center font-medium tracking-tight rounded-lg border transition-colors focus:outline-none focus:ring-offset-2 focus:ring-2 focus:ring-inset h-9 px-4 shadow focus:ring-dark-900 border-transparent bg-primary-600 hover:bg-primary-500 focus:bg-primary-700 focus:ring-offset-primary-700">
                        <SArrowRight className="h-5 w-5"/>
                    </button>
                </div>
            }
            </div>
        </Modal>
    }</div>
  
}

const ProjectsHeader = ()=>{
    const [projects, updateProjects, isLoading] = useData([],"podio/projects");
    const [summary, setSummary] = useState({
        "In production": 0,
        "Awaiting feedback": 0,
        "Live": 0,
        "Completed": 0
    })
    const [statusState, setStatusState] = useState();
    const recursiveSummary = (items, state)=>{
        items.forEach(item => {
            state[item.status]++;
            state["Total"]++;

            if(item.items&&item.items.$values.length>0){
                recursiveSummary(item.items.$values, state);
            }
        });
    }

    statusCallback=(s)=>{
        let newState = {};
        (s??[]).forEach(st=>{
            newState[st]=true;
        })
        setStatusState(newState);
    };

    useEffect(()=>{
        let newState = {
            "In production": 0,
            "Awaiting feedback": 0,
            "Live": 0,
            "Completed": 0,
            "Total": 0
        };
        recursiveSummary(projects, newState);

        setSummary(newState);
    }, [projects])

    const SummarySquare = ({label, value, status}) =>{
        const [isActive, setIsActive] = useState(false);

        useEffect(()=>{
            if(status.length===0&&statusState&&Object.keys(statusState).length===0||statusState&&statusState[status[0]]){
                setIsActive(true)
            } else{
                setIsActive(false)
            }
        }, [statusState])

        
        

        

        const handleClick = (e) => {
            if(e.ctrlKey){
                status.forEach(s=>{
                    let index = currentStatus.findIndex(st=>st===s);
                    if(index!==-1){
                        currentStatus.splice(index, 1);
                    }else{
                        currentStatus.push(s);
                    }
                    
                })
                setStatus(currentStatus);
            } else{

                setStatus(status)
            }
        }
        return <li className="">
            <button onClick={handleClick} className={`flex py-4 px-10  flex-col justify-center items-center `}>
            <div className={`flex  flex-col justify-center items-center ${isActive&&"bg-dark-600"} w-24 h-24 rounded-full`}>
            <span className=" text-3xl text-gray-50 w-fit">
                {value}
            </span>
            <span className="text-sm text-gray-200 font-normal w-fit">
                {label}
            </span>
            </div>
            </button>
        </li>
    }
    return <div className='justify-between items-center flex'><span>Projects</span>
    <div className="hidden lg:block">
        <ul className="flex divide-x divide-dark-300">
            <SummarySquare label="All" value={summary.Total} status={[]}/>
            <SummarySquare label="In production" value={summary["In production"]} status={["In production"]}/>
            <SummarySquare label="Awaiting feedback" value={summary["Awaiting feedback"]} status={["Awaiting feedback"]}/>
            <SummarySquare label="Live" value={summary["Live"]} status={["Live"]}/>
            <SummarySquare label="Completed" value={summary["Completed"]} status={["Completed"]}/>
        </ul>
        </div>
        </div>

};

export {ProjectsHeader};

export default Projects;