import { useEffect, useState } from "react"
import Number from "./Number";


const Range = ({min,max,minValue, maxValue,onChange}) => {
    const [minNumber, setMinNumber] = useState();
    const [maxNumber, setMaxNumber] = useState();
    useEffect(()=>{
        
        setMinNumber(parseInt(minValue));
        setMaxNumber(parseInt(maxValue));
    }, [minValue, maxValue])
    const handleMinChange = (v)=>{
        
        setMinNumber(v);
        onChange(v, maxNumber);

    }
    const handleMaxChange = (v)=>{
        setMaxNumber(v);
        onChange(minNumber, v);
    }

    return <div className="flex items-center space-x-2">
            <Number placeholder="Min" min={min} max={maxNumber} value={minNumber} onChange={handleMinChange}/>
            <span className="h-full text-gray-300">-</span>
            <Number placeholder="Max" min={minNumber} max={max} value={maxNumber} onChange={handleMaxChange}/>
           </div>;
}

export default Range;