import Label from "./Label"
import React from "react";

const LabelInputPair = ({children, label, name, help}) => {


    return <div className="space-y-2">
        <Label htmlFor={name}>
            {label}
        </Label>
        {React.cloneElement(children, {name:name})}
        {help&&<div className="py-3 px-1"><p className="text-xs text-gray-300">{help}</p></div>}
    </div>
}

export default LabelInputPair;