import DateFormat from "../components/DateFormat";
import Filter from "../components/Filter";
import MultiSelect from "../components/form/MultiSelect";
import Switch from "../components/form/Switch";
import { MultiDimTable } from "../components/MultiDimensionalDataTable";
import PivotTable from "../components/PivotTable";
import useData from "../hooks/useData";
import "../css/ReviewRecords.css";
import { useEffect, useState } from "react";
import Alert from "../components/svg/Alert";
import { useSearchParams } from "react-router-dom";
import usePubSub from "../hooks/usePubSub";
import Popup from "../components/Popup";
import ThreeDots from "../components/svg/ThreeDots";
import { sendNotification } from "../components/Notification";
import axiosClient from "../axiosClient";
import Modal from "../components/Modal";

const DisplayStatus = (status)=>{
    if(!status){
        return <></>
    }
    const className = 'capitalize inline-flex items-center justify-center min-w-max px-8 h-full w-full overflow-hidden whitespace-nowrap text-sm font-medium tracking-tight rounded-xl ';

    switch(status){


            case 'Exclude':
            return <span className={`${className} text-[#C93131] bg-[#C9313140]`}><span>Review</span></span>;
        case 'New':

             return <span className={`${className} text-[#43C931] bg-[#43C93140]`}><span>{status.toLowerCase()}</span></span>;



             default:
                return <span className={`${className} text-[#8D95A5] bg-[#8D95A540]`}><span>{status.toLowerCase()}</span></span>;
    }

}

const StatusFilter = ({ onChange, value }) => {
    return <MultiSelect value={value} onChange={onChange} placeholder="All">
        <option value="New">New</option>
        <option value="Success">Success</option>
        <option value="Unprocessed">Unprocessed</option>

    </MultiSelect>
}


const ReviewFilter = ({ onChange }) => {
    const sParams = useSearchParams();

    return <Filter searchParams={sParams} onChange={onChange} filters={[
        {
            name: "status",
            element: <StatusFilter />,
            label: "Status",
            defaultValue: []
        }
    ]} />
}
const DisplayName = (row)=>{
    return <div className="flex items-center justify-between">
        <span className="overflow-hidden text-ellipsis">{row.name}</span>
        {(row.status!=="New"&&row.count_new>0)&&<div className=" bg-red-700 px-2 rounded-full text-xs font-light  text-white ">{row.count_new}</div>}
    </div>
}
const Exclude = (row, sendMessage)=>{

    if(row.status!="New"){
        return <></>
    }

    const handleChange = (v)=>{
        if(!row.id){
            row.items.forEach(element => {
                element.ignore=v;
                sendMessage({
                    id:element.id,
                    uploadId:element.uploadId,
                    exclude:element.exclude,
                    ignore:v,
                    action:"ignore"
                })
            });
        } else{
            sendMessage({
                id:row.id,
                uploadId:row.uploadId,
                exclude:row.exclude,
                ignore:v,
                action:"ignore"
            })
        }

        row.exclude=v;
    }


    return <>
    <Switch value={row.ignore} onChange={handleChange}></Switch></>
}

const DisplayAlert = (alert)=>{
    return <div className="flex items-center justify-center h-full" title={alert}><Alert className="h-5 w-5 text-primary-500 transition"/></div>
}

const EnrichButton = ({uploadId, recordId})=>{
    const [enrichPreview, setEnrichPreview] = useState(null);

    const enrich = async ()=>{
        await axiosClient({
            method:"GET",
            url:`imports/enrichments?recordId=${recordId}&uploadId=${uploadId}&action=preview`
        }).then((response)=>{
            setEnrichPreview(response.data);
            console.log(response.data);
        })
    }

    const finish = async (action)=>{
        await axiosClient({
            method:"GET",
            url:`imports/enrichments?recordId=${recordId}&uploadId=${uploadId}&action=${action}`
        }).then((response)=>{
            setEnrichPreview(response.data);
        })
    }
    const approve = async () => await finish("approve");
    const deny = async () => await finish("deny");

    return  <>
    <button onClick={enrich} className="flex focus:ring-offset-primary-700 focus:bg-primary-700 hover:bg-primary-500 focus:text-white hover:text-white transition items-center w-full h-8 px-4 text-sm font-medium">Enrich</button>
    {enrichPreview&&
        <Modal isVisible={enrichPreview}><div>{JSON.stringify(enrichPreview)}</div>
        <div>
            <button onClick={approve} type="button">Approve</button>
            <button onClick={deny} type="button">Deny</button>
        </div>
            </Modal>
    }</>
}

const Actions = (item)=>{
    const copyLeadId = ()=>{
        navigator.clipboard.writeText(item.id);
        sendNotification("success", "Copied to clipboard!")
    }

    const closeLead = async()=>{
        let id = item.id??item.items.map(i=>i.id).join(',');

        console.log(id);

        await axiosClient({
            method:"POST",
            url:`presales/enqueue?leads=${id}&action=CloseInDialogue`
        }).then(()=>{
            sendNotification("success", "Closed lead")

        })
    }

    const blacklist = ()=>{
        
    }
    

    return <Popup buttonClass="flex items-center justify-center w-5 h-5 rounded-full hover:bg-gray-500/5 focus:outline-none  text-primary-500 focus:bg-primary-500/10" className="right-0 rtl:right-auto rtl:left-0 mt-2 shadow-xl rounded-xl w-52 top-full" icon={<ThreeDots className="text-gray-500  rotate-90 w-4 h-4"/>}>
        <div className="overflow-hidden  bg-dark-900 shadow rounded-xl text-gray-300">
           {
            item.status!=="New"&&<>
            {!item.items&&
             <button onClick={copyLeadId} className="flex focus:ring-offset-primary-700 focus:bg-primary-700 hover:bg-primary-500 focus:text-white hover:text-white transition items-center w-full h-8 px-4 text-sm font-medium">Copy lead id</button>}
             <button onClick={closeLead} className="flex focus:ring-offset-primary-700 focus:bg-primary-700 hover:bg-primary-500 focus:text-white hover:text-white transition items-center w-full h-8 px-4 text-sm font-medium">Close lead</button></>
           }
            <button onClick={blacklist} className="flex focus:ring-offset-primary-700 focus:bg-primary-700 hover:bg-primary-500 focus:text-white hover:text-white transition items-center w-full h-8 px-4 text-sm font-medium">Blacklist</button>
            <EnrichButton uploadId={item.uploadId} recordId={item.id}/>
        </div>

    </Popup>
}

const ReviewRecords = ({uploadId}) => {
    const [records, updateRecords, loading] = useData([],"imports/get");
    const [sendMessage] = usePubSub("Presales");
    const [otherloading, setOtherloading] = useState(true);
    
    useEffect(()=>{
        setTimeout(()=>{
            updateRecords().then(()=>{

                setOtherloading(false);
            });
        }, 5000)
    },[])


    return <div>
    <MultiDimTable loading={otherloading} items={records} searchable="name" filter={<ReviewFilter/>} >
        <td field="items" nested/>
        <td display={DisplayName}  header="Name" className="line-clamp-1 whitespace-nowrap max-w-[15.5rem]"/>
        <td display={(description)=><span className="overflow-hidden text-ellipsis block">{description}</span>} field="description"  header="Description" className="line-clamp-1 whitespace-nowrap w-[22rem]"/>
        <td field="nextCall"  header="Next call" display={DateFormat} className=" max-w-[10.5rem]"/>
        <td field="alert"  header="Alert" display={DisplayAlert}  className="w-16"/>
        <td field="status"  header="Status" display={DisplayStatus} className="w-48"/>
        <td header="Ignore" display={(r)=>Exclude(r, sendMessage)} className="excludedims"/>
        <td header="" display={Actions} />
        {/* <td header="id" field="id" /> */}


    </MultiDimTable>
</div>
}

export default ReviewRecords;