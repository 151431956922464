import { useEffect, useState } from "react";
import Calendar from "../svg/Calendar";
import OutsideAlerter from "../OutsideAlerter";
import FadeIn from "../FadeIn";


const DatePicker = ({onChange, value, name, min, max})=>{
    const days = [
        "Mon",
        "Tue",
        "Wed",
        "Thu",
        "Fri",
        "Sat",
        "Sun"
    ], months = [
        "January",
        "Febuary",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December"
    ]

    const [open, setOpen] = useState(false);

    const [year, setYear] = useState(1970);
    const [month, setMonth] = useState(0);
    const [date, setDate] = useState(1);
    const [daysInMonth, setDaysInMonth] = useState([]);
    const [minDate, setMinDate] = useState();
    const [maxDate, setMaxDate] = useState();
    
    useEffect(()=>{
        if(min){
            setMinDate(new Date(min));
        } else{
            setMinDate(null);
        }
        if(max){
            setMaxDate(new Date(max));
        } else{
            setMaxDate(null);
        }
    }, [min, max]);

    useEffect(()=>{
        if(minDate&&(new Date(year,month,date))<minDate){

            setFullDate(minDate);
        }
    }, [minDate])
    useEffect(()=>{
        
        if(maxDate&&(new Date(year,month,date))>maxDate){

            setFullDate(maxDate);
        }
    }, [maxDate])
   
    useEffect(()=>{
        let date;
        if(value){
            let ts = Date.parse(value);
          
            if(ts>0){
                
                date=new Date(ts);

            }
        }
        if(!date){
            date=new Date(Date.now())

        }
        setYear(date.getFullYear());
        setMonth(date.getMonth());
        setDate(date.getDate());
    }, [value])

    useEffect(()=>{
        setDaysInMonth(getDaysInMonth());

    }, [month, year])

    const setFullDate = (value)=>{
        let date=value.getDate(),
            month=value.getMonth(),
            year=value.getFullYear();

        setDate(date);
        setMonth(month);
        setYear(year);

        handleChange(date,month,year);
    }
    const toggleOpen = (e)=>{
        setOpen(!open);
    }
    const handleMonth = (e)=>{
        let m = parseInt(e.target.value);
        setMonth(m);
        handleChange(date,m,year);
    }
    const handleYear = (e)=>{
        let y = parseInt(e.target.value);
        setYear(y);
        handleChange(date,month,y);
    }
    const handleDate = (e)=>{
        let d = parseInt(e);
        setDate(d);
        handleChange(d,month,year);
    }
    const handleChange = (day,month,year) =>{
        let monthStr = month<9?`0${month+1}`:month+1;
        let dateStr = day<10?`0${day}`:day;
        onChange(`${year}-${monthStr}-${dateStr}`);



    }

    const getDaysInMonth = ()=>{
        
        let fd = new Date(year,month,1)
        let firstDate = fd.getDay()-2;
        if(firstDate===-2){
            firstDate=5;
        }
        let daysInMonth;
    
        switch(month+1){
            case 1:
            case 0:
            case 3:
            case 5:
            case 7:
            case 8:
            case 10:
            case 12:
                daysInMonth=31;
                break;
            case 2:
                daysInMonth=year%4==0?29:28;
                break;
            case 4:
            case 6:
            case 9:
            case 11:
                daysInMonth=30;
                break;
        }

        let dates = [];
        let index = 0;
        while(true){
            if(index<firstDate){
                dates[index]=null;
            } else{
                if(index-firstDate>daysInMonth){
                    break;
                } else{
                    dates[index]=index-firstDate;
                }
            }
            index++;
        }
        return dates;
    }

    const handleClear = (e)=>{
        switch(e.code){
            case "Delete":
            case "Backspace":
                onChange(null);
                break;
        }
    }

    return <OutsideAlerter onOutsideClick={()=>setOpen(false)} className="relative">
        <button onKeyDown={handleClear} type="button" onClick={toggleOpen} className="bg-dark-600 cursor-text relative w-full border py-2 pl-3 pr-10 text-left rounded-lg shadow-sm focus-within:border-primary-600 focus-within:ring-1 focus-within:ring-inset focus-within:ring-primary-600 border-dark-300">
            <input type="text" disabled className="p-0 bg-transparent cursor-text  border-none outline-0" placeholder="Select date" value={value?(new Date(value)).toLocaleDateString("en-UK", {
        day: 'numeric',
        month:'long',
        year:'numeric'
    }):""}/>
    <input type="hidden" value={`${month+1}/${date}/${year}`} name={name}/>
            <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none rtl:right-auto rtl:left-0 rtl:pl-2">
            <Calendar className="h-5 w-5 text-gray-400"/>

            </span>
        </button>

<FadeIn visible={open} className={"absolute z-10 my-1 border rounded-lg shadow-md bg-dark-600 border-dark-300 p-4 min-w-[16rem] w-fit"}>
<div className="space-y-3">
                    <div className="flex items-center justify-between space-x-1 rtl:space-x-reverse">
                        <select value={month} onChange={handleMonth} className="grow px-1 py-0 text-lg font-medium text-gray-200 border-0 cursor-pointer focus:ring-0 focus:outline-none bg-dark-600">
                            {
                                months.map((month,index)=>{
                                    let disabled=false;
                                    if(minDate&&(minDate.getFullYear()==year&&minDate.getMonth()>index)){
                                        disabled=true;
                                    }
                                    if(maxDate&&(maxDate.getFullYear()==year&&maxDate.getMonth()<index)){
                                        disabled=true;
                                    }
                                    return <option disabled={disabled} value={index} key={index}>{month}</option>;
                                })
                            }
                        </select>
                        <input min={minDate?minDate.getFullYear():1900} max={maxDate?maxDate.getFullYear():3000} value={year} onChange={handleYear} type="number" className="w-20 p-0 text-lg text-right border-0 focus:ring-0 focus:outline-none bg-dark-600 text-gray-200"  />
                    </div>
                    <div className="grid grid-cols-7 gap-1">
                        {
                            days.map((day,index)=><div key={index} className="text-xs font-medium text-center text-gray-200">{day}</div>)
                        }
                    </div>
                    <div className="grid grid-cols-7 gap-1">
                        {
                            daysInMonth.map((day, index)=>{
                                if(!day){
                                    return <div key={index}></div>
                                } else{
                                    let disabled=false;
                                    if(minDate&&(minDate.getFullYear()==year&&minDate.getMonth()==month&&minDate.getDate()>day)){
                                        disabled=true;
                                    }
                                    if(maxDate&&(maxDate.getFullYear()==year&&maxDate.getMonth()==month&&maxDate.getDate()<day)){
                                        disabled=true;
                                    }
                                    let className="text-sm leading-loose text-center transition duration-100 ease-in-out rounded-full text-gray-300 cursor-pointer hover:bg-primary-200 hover:text-gray-600 disabled:opacity-50 disabled:cursor-not-allowed disabled:hover:bg-transparent disabled:hover:text-gray-300";
                                    if(day===date){
                                        className+=" bg-primary-600";
                                    }
                                    return <button disabled={disabled}  type="button" onClick={e=>handleDate(day)} className={className} key={index}>{day}</button>
                                }
                            })
                        }
                    </div>
                </div>
</FadeIn>
    </OutsideAlerter>
}
export default DatePicker;