import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { sendNotification } from "../components/Notification";
import axiosClient from "../axiosClient";
import Cookies from "js-cookie";
const useSwitch =() =>{
    const [error, setError] = useState();
    const navigate = useNavigate();
    useEffect(()=>{
        if(error){
            sendNotification("danger", "Something went wrong");
        }
    }, [error]);
    return customer => {
        sessionStorage.clear();
        axiosClient({
            method: "GET",
            url: `/auth/customer/token/${customer}`
        })
            .then((response) => {
                setError(null);
                if(response.data.success){
                    
                    Cookies.set('mm-customer-portal-token', response.data.token, {expires: 30});
                    navigate("../../links");
                    window.location.reload(false);
                } 
                
            }).catch(setError);
    }
}
export default useSwitch;