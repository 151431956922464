import axiosClient from "../axiosClient";

const useRest = (url) => {

    return {
        get: async (params) => await axiosClient({
            method:"get",
            url:url+(params?params:"")
        }),
        post: async (body, params) => await axiosClient({
            method:"post",
            url:url+(params?params:""),
            data:body
        }),
        put: async (body, params) => await axiosClient({
            method:"put",
            url:url+(params?params:""),
            data:body
        }),
        delete: async (id) => await axiosClient({
            method:"delete",
            url:url+`/${id}`
        })
        }
}
export default useRest;