import React from 'react';
import Check from './svg/Check';
const ProgressBar = ({ steps, activeStep }) => {
  const totalSteps = steps.length;
  const width = `${(100 / (totalSteps - 1)) * (activeStep - 1)}%`;

  return (
    <div className="w-full mx-auto px-4 mb-8">
      <div className="relative flex justify-between mt-16">
        <div
          className="absolute bg-gray-500 h-[2px] w-full top-1/2 transform -translate-y-1/2"
        ></div>
        <div
          className="absolute bg-primary-500 h-[2px]"
          style={{ width, transition: '0.4s ease', top: '50%', transform: 'translateY(-50%)' }}
        ></div>
        {steps.map(({ step, label }) => (
          <div className="relative z-10" key={step}>
            <div
              className={`w-6 h-6 rounded-full flex justify-center items-center border-2 transition-all ease-in-out ${
                activeStep >= step ? 'border-primary-500  ' : 'border-gray-500 '
              } ${activeStep>step?'bg-primary-500':'bg-dark-900'}`}
            >
              {activeStep > step &&<div className=" text-white"><Check className="w-5 h-5"/></div>}
            </div>
            <div className="absolute left-1/2 transform -translate-x-1/2">
              <span className="text-xs text-white">{label}</span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ProgressBar;
