import { useEffect, useState } from "react";
import { redirect, useNavigate, useParams, useSearchParams } from "react-router-dom"
import  axiosClient from "../axiosClient";
import Cookies from "js-cookie";
import { atob } from "react-native-quick-base64";
import useAuth from "../hooks/useAuth";

const LoggedIn = ({})=>{
    const [searchParams,] = useSearchParams();
  const [userInfo, checkAuth] = useAuth();
    const navigate = useNavigate();
    const state = searchParams.get("state");

    useEffect(()=>{
        if(userInfo){
            try {
                navigate(atob(state));
            } catch (error) {
                navigate("/")
            }
        }
    },[userInfo])

   

    return <></>
}

const GoogleCallback = ({}) => {
    const [response, setResponse] = useState({});
    const [searchParams, setSearchParams] = useSearchParams();
    const [userInfo, checkAuth] = useAuth();


    const code = searchParams.get("code");
    const state = searchParams.get("state");
    const hd = searchParams.get("hd");

    let navigate = useNavigate();
    
    

    useEffect(()=>{
        let data = {
            redirect: `https://${window.location.host}/auth/google-callback`,
            code: code,
            hd:hd
        }
        let body = {
            method:"google",
            value: JSON.stringify(data)
        }

        axiosClient({
            method: "post",
            url: `/auth`,
            data: body
        })
            .then((response) => {
                if(response?.data?.success){

                    setResponse(response.data);
                } else{
                    let email = response?.data?.email;
                    navigate("/unauthorized?email="+email);
                    
                }
                
            }).catch(()=>{});
        
    }, [])

    useEffect(()=>{
        if(response.success){
            Cookies.set('mm-customer-portal-token', response.token, {expires: 30});
           
            
            checkAuth((ui)=>{
                
                navigate("/logged-in?state="+state);

                window.location.reload(false);
            
           
        });
            
        }
    }, [response])

    return <></>
}
export {LoggedIn}
export default GoogleCallback;