import { useEffect, useState } from "react";
import DateFormat from "../components/DateFormat";
import PivotTable from "../components/PivotTable";
import useData from "../hooks/useData"
import CheckCircle from "../components/svg/CheckCircle";
import Check from "../components/svg/Check";
import QuestionMark from "../components/svg/QuestionMark";
import Logo from "../components/svg/Logo";
import useAuth from "../hooks/useAuth";
import Document from "../components/svg/Document";
import DocumentAdd from "../components/svg/DocumentAdd";
import useRest from "../hooks/useRest";
import { sendNotification } from "../components/Notification";
import axiosClient from "../axiosClient";
import Modal from "../components/Modal";
import RichText from "../components/form/RichText";
import ConfirmationButton from "../components/ConfirmationButton";
import Trash from "../components/svg/Trash";
import { SArrowRight } from "../components/svg/Arrow";
import { createPortal } from "react-dom";
import Masonry, {ResponsiveMasonry} from "react-responsive-masonry"
import MultiSelect from "../components/form/MultiSelect";
import { useSearchParams } from "react-router-dom";
import Filter from "../components/Filter";

const DisplayStatus = ({status,type,subdeliverable_type})=>{
    let color = "";

    switch(status){
        case "Working":
            color=" text-blue-500 border-blue-500";
            break;
        case "Review":
            color=" text-primary-500 border-primary-500";
            break;
        case "Completed":
            color=" text-green-500 border-green-500";
            break;
        case "Planned":
            color=" text-gray-500 border-gray-500";
            break;
    }
    return <div className={` w-fit py-1 px-2 text-xs border-[1px] rounded-md ${color}`}>{type} | {status.toLowerCase()}</div>
}

const Milestone =({title, status, type, assignments,initial, deliverable,app_item_id,item_id,admin, deliverable_id, subdeliverable_id,subdeliverable_type, funcs, hasAttachments, customerName})=>{
    const [setSelectedId, setAddAttachmentCallback] =  funcs
    const ViewAttachments = ()=>{
        const callback =(v)=>{
            
        }
        const handleClick = (e)=>{
            setSelectedId(`${deliverable_id},${subdeliverable_id}`);
            setAddAttachmentCallback({method:callback});
        }
        // if(selectedId===item.id){
        //     return <></>
        // }
        if(admin){
            return <button onClick={handleClick} className={hasAttachments?"":"opacity-0 group-hover:opacity-100 transition-opacity"}>
                {
                    hasAttachments?<Document className="h-5 w-5"/>:<DocumentAdd className="h-5 w-5"/>
                }
            </button>;
        } else{
            if(hasAttachments){
                return <button onClick={handleClick}>
                    <Document className="h-5 w-5"/>
                </button>;
            } else{
                return <></>
            }
        }
        
        
       }
    
    
    let thisAssignments = assignments.filter(a=>a.text);

    return <div className=" bg-dark-600 rounded-lg py-3 px-4 group h-fit">
        <div className="flex flex-col w-fit">
        <a target="_blank" href={admin&&`https://podio.com/mindmillaps/benjatester/apps/milestones/items/${app_item_id}`} className="text-white text-md first-letter:capitalize">{deliverable}</a>
        <span className="text-gray-400 text-sm">{subdeliverable_type} | {title}</span>
        </div>
        {
            (thisAssignments&&thisAssignments.length>0)&&<div className="mt-6 space-y-3">
                {
                    thisAssignments.map((assignment, index)=>{
                        return <div key={index} className="ml-1 flex space-x-3 items-center text-[rgba(255,255,255,.44)] overflow-hidden">
                            <div className="h-5 w-5 rounded-md border-[rgba(255,255,255,.44)] border-[1px] flex items-center justify-center">
                                <Check className={`h-5 w-5 text-primary-500 ${assignment.status==="active"?"opacity-0":""}`}>

                                </Check>

                            </div>
                            <span className={`break-words overflow-hidden ${assignment.status==="active"?"":" line-through"}`}>
                            {assignment.text}
                            </span>
                            {
                                assignment.description&&<div className="h-5 w-5 rounded-md flex items-center justify-center" title={assignment.description}>
                                <QuestionMark className={`h-5 w-5  ${assignment.status==="active"?"":""}`}>

                                </QuestionMark>

                            </div>
                            }
                        </div>
                    })
                }
                </div>
        }
        <div className="mt-6 flex justify-between items-center">
            <div className="space-x-3 flex">
            <DisplayStatus status={status} type={type}/>
            <ViewAttachments/>
        
            </div>
            <div className="flex" title={(status==="Review"||(thisAssignments&&thisAssignments.length>0))?`${customerName} + Mindmill`:"Mindmill"}>
                {(status==="Review"||(thisAssignments&&thisAssignments.length>0))&&<div className="rounded-full flex flex-row-reverse items-center justify-center bg-gray-500 border-dark-600 border-2 w-6 h-6 translate-x-[20%] text-xs font-bold select-none">{initial}</div>}
                <div className="rounded-full flex items-center justify-center bg-mindmill border-dark-600 border-2 w-6 h-6 "><Logo className="w-2 text-white "/></div>

            </div>
        </div>
    </div>
}

const TimelineFilter = ({onChange, customerName}) => {

    const sParams = useSearchParams();

    return <Filter searchParams={sParams} onChange={onChange} filters={[
        {
            name: "status",
            element: <StatusFilter/>,
            label: "Status",
            defaultValue: ["Working","Review"]
        },
        {
            name: "assigned",
            element: <AssignedFilter customerName={customerName}/>,
            label: "Assigned",
            defaultValue: []
        },
        
        {
            name: "deliverable",
            element: <DeliverableFilter/>,
            label: "Deliverable",
            defaultValue: []
        },
        
    ]}/>
};
const DeliverableFilter = ({onChange, value})=>{
    const [deliverables,] = useData([], "podio/deliverables", false);

    return <MultiSelect value={value} onChange={onChange} placeholder="All">
        {
            deliverables.map((deliverable,index)=>{
                
                return <option value={deliverable} key={index}>{deliverable==""?"None":deliverable}</option>
            })
        }
    </MultiSelect>
}
const StatusFilter = ({onChange, value})=>{
    return <MultiSelect value={value} onChange={onChange} placeholder="All">
        <option value="Planned">Planned</option>
        <option value="Working">Working</option>
        <option value="Review">Review</option>
        <option value="Completed">Completed</option>
    </MultiSelect>
}
const AssignedFilter = ({onChange, value, customerName})=>{
    return <MultiSelect value={value} onChange={onChange} placeholder="All">
        <option value="customer">{customerName}</option>
        <option value="mindmill">Mindmill</option>
    </MultiSelect>
}


const Timeline = ({})=>{
    const [filters, setFilters] = useState([]);
    const [timeline,ui, isLoading] = useData([], "podio/timeline", true, null, true, filters);
    const [auth,] = useAuth();
    const [initial, setInitial] = useState("X");
    const [attachments, setAttachments] = useState();
    const [selectedId, setSelectedId] = useState();
    const [addAttachmentCallback, setAddAttachmentCallback] = useState({method:null});
    const [text, setText] = useState();
    const {get, post} = useRest("podio/projects/");
    const titleHeader = document.getElementById('titleHeader'); 
    


    useEffect(()=>{
        setInitial(auth?.customer?.name?.[0]);
    }, [auth])


    const addAttachment = ()=>{
        post({
            text:text
        },`${selectedId.split(',')[1]}/attachments`).then(()=>{
            updateItems();
            setText("");
            sendNotification('success', "Success!")
        }).catch(error=>{
            sendNotification("danger", "Something went wrong")
        });
    }
    useEffect(()=>{
        if(selectedId){
            updateItems();
        } else{
            setAttachments(null);
        }
    }, [selectedId])
 

    const updateFilters = (filterValues) =>{
        let newFilters=Object.entries(filterValues).map(([key, value], index)=>{
         
            if(Array.isArray(value)){
                if(value.length===0){
                    return (item)=>true;
                } else{
                    return (item)=>value.includes(item[key])
                }
            } else if(value?.type==="dateRange"){
                let endDate;
                if(value.startDate){
                    let startDate = Date.parse(value.startDate);
                    if(value.endDate){
                        endDate = Date.parse(value.endDate);
                        return (item)=>{
                            if(!item[key]){
                                return false
                            }
                            
                            let itemDate = Date.parse(item[key]);
                           
                            
                            return itemDate>=startDate&&itemDate<=endDate;
                        }
                    }
                    return (item)=>item[key]&&Date.parse(item[key])>=startDate;
    
    
                } else if(value.endDate){
                    endDate = Date.parse(value.endDate);
    
                    return (item)=>item[key]&&Date.parse(item[key])<=endDate;
                }
                
                return (item)=>true;
            }else{
                if(!value){
                    return (item)=>true;
    
                } else{
                    return (item)=>item[key]==value;
    
                }
            }
        });
        setFilters(newFilters);
       }
   const CloseAttachments = (e)=>setSelectedId(null);


    const updateItems = ()=>{
        if(selectedId){
            get(`${selectedId}/attachments`).then(response=>{
                setAttachments(response.data)
                if(addAttachmentCallback.method){
                    addAttachmentCallback.method((response.data?.length??0)>0);
                }
            });
        }
    }
    const deleteAttachment = (id)=>{
        axiosClient({
            url:`podio/attachments/${id}`,
            method: "DELETE"
        }).then(()=>{
            updateItems();
            
        })
    }

   

    return <div className="grid grid-cols-1 md:grid-cols-7 md:gap-12 gap-6">
        {timeline.length>0&&createPortal(<><TimelineFilter onChange={updateFilters} customerName={auth?.customer?.name}/></>, titleHeader, "titleHeader")}
       
        {
            isLoading&&<><div className="flex w-full md:space-x-0 space-x-6 md:contents">
            <div className="text-lg md:text-right bg-dark-600 text-transparent overflow-visible md:w-auto whitespace-nowrap rounded-xl animate-pulse">
                16 July 2024
            </div>

            <div className=" md:col-span-6 border-b-[1px] border-b-dark-600 -translate-y-1/2 w-full animate-pulse">
</div>
            </div>
            <div></div>
            <div className="bg-dark-600 rounded-lg h-32 animate-pulse md:col-span-2"></div>
            <div className="bg-dark-600 rounded-lg h-32 animate-pulse  md:col-span-2"></div>
            <div className="bg-dark-600 rounded-lg h-32 animate-pulse  md:col-span-2"></div>
            </>
        }
        {
            timeline.map((date,index)=>{

                let items = date.items.filter((item,index)=>{

                    return filters.every((filter)=>filter(item));

                });

                if(items.length==0){
                    return <></>;
                }
         


                return <div className="contents" key={index}>
                <div className="flex w-full md:space-x-0 space-x-6 md:contents">
                <div className="text-sm uppercase font-bold md:text-right text-white overflow-visible md:w-auto whitespace-nowrap ">
                    
                    {DateFormat(date.deadline)}
                </div>
                <div className=" md:col-span-6 border-b-[1px] border-b-zinc-700 -translate-y-1/2 w-full">
                </div>

                </div>
                <div className="md:block hidden "></div>
              
                <div className="md:col-span-6">
                    <ResponsiveMasonry columnsCountBreakPoints={{768:2, 0:1, 1280:3}}>
                    <Masonry  gutter="1.5rem">
                    {
                        items.map((m,i)=><Milestone key={i} title={m.title} status={m.status} type={m.type} assignments={m.assignments} initial={initial} deliverable={m.deliverable} app_item_id={m.app_item_id} item_id={m.item_id} admin={auth.admin} subdeliverable_id={m.subdeliverable_id} subdeliverable_type={m.subdeliverable_type} deliverable_id={m.deliverable_id} funcs={[setSelectedId,setAddAttachmentCallback]} hasAttachments={m.has_attachments} customerName={auth?.customer?.name}/>)
                    }
                </Masonry>
                    </ResponsiveMasonry>
                
                </div>
          
                <div className="md:hidden mb-12">

                </div>
                </div>
            })
        }
        {
        attachments&&<Modal className="rounded-xl border-dark-300 border p-6 bg-dark-600 h-fit " onHide={CloseAttachments}>
            <div className="">
            <div className="flex justify-between mb-6 items-center">
                <span className="text-xl font-bold tracking-tight">
                    Links / Info
                </span>
           
                </div>
            {
                
                <ul className="flex flex-col space-y-6 text-gray-300">
                {
                    attachments.map((a,index)=><li key={index} className="  space-y-2 flex flex-col ">
                        <span className="self-end">{DateFormat(a.timestamp)}</span>
                        <div className="group border border-dark-300 rounded-lg  p-4 flex justify-between"><div className=" w-[calc(100%-28px)]">
                        <RichText readOnly value={a.text} className=" cursor-text block w-full "/>
                            </div> 
                        {
                            auth.admin&&<div className="px-2 hidden group-hover:block">
                            <ConfirmationButton  onClick={()=>deleteAttachment(a.id)} content="Are you sure?" title="Delete attachment" onConf>
                            <Trash className="h-3 w-3"/>
                            </ConfirmationButton>
                        </div>
                        }
                        </div>
                    </li>)
                }
            </ul>
            }
            {
                auth.admin&&<div className="mt-12 pt-6 space-y-2 flex flex-col">
                    <RichText placeholder="Write your comment" value={text} onChange={setText}/>

                    <button type="button" onClick={addAttachment} className="self-end inline-flex items-center justify-center font-medium tracking-tight rounded-lg border transition-colors focus:outline-none focus:ring-offset-2 focus:ring-2 focus:ring-inset h-9 px-4 shadow focus:ring-dark-900 border-transparent bg-primary-600 hover:bg-primary-500 focus:bg-primary-700 focus:ring-offset-primary-700">
                        <SArrowRight className="h-5 w-5"/>
                    </button>
                </div>
            }
            </div>
        </Modal>
    }
    </div>
    
}
export default Timeline;