import { useEffect } from "react"
import axiosClient from "../axiosClient";

const useLog = (controller, action, method="GET") => {
    useEffect(()=>{
        axiosClient({
            url: `activitylog/${controller}/${action}`,
            method: method
        }).then(()=>{}).catch(()=>{});
    });
    return [];
}

export default useLog;