import useData from "./useData";
import axios from "axios";
import Cookies from 'js-cookie';

const usePubSub = (topic)=>{
    const [bearerToken] = useData("", "bigquery/pubsub");

    const sendMessage = (value)=>{
        let b64Value = btoa(JSON.stringify(value));
        return axios({
            method:"POST",
            url:`https://pubsub.googleapis.com/v1/projects/analytics-dev-317508/topics/${topic}:publish`,
            data:{
                "topic": `projects/analytics-dev-317508/topics/${topic}`,
                "messages":[{
                  "attributes":{
                    "token": Cookies.get("mm-customer-portal-token")
                  },
                  "data":b64Value
                }]
            },
            headers:{
                "Authorization": `Bearer ${bearerToken.token}`
            }
        });
    }

    return [sendMessage];
}

export default usePubSub;