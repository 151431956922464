import React, { useEffect, useState } from "react"
import { MultiDimTable } from "./MultiDimensionalDataTable";

const PivotTable = ({children, items, filter, loading, autoExpand, filterLevel, showIfChild = true}) => {
    const [groupedItems, setGroupedItems] = useState([])
    const [transforming, setTransforming] = useState(true);
    const [actuallyLoading, setActuallyLoading] = useState(true);
    useEffect(()=>{
        let result = loading||transforming;
        setActuallyLoading(result);
    }, [loading, transforming])

    const recursiveListify = (entries) => {
        if(entries){
            return Object.entries(entries).map(([key, entry])=>{
                let row = {
                    key: key.replace(/\$id_.*$/, "")
                };
                
                
                if(entry.items&&Object.keys(entry.items).length>0){
                    row.items= recursiveListify(entry.items);
                }
                Object.entries(entry).filter(([key,])=>key!="items").forEach(([key, value])=>{
                    row[key]=value;
                });
                return row;
            });
        }
    }
    useEffect(()=>{
        setTransforming(true);
        let groupedObj = {};
        items?.forEach(item => {
            let row = groupedObj;
            children.filter(c=>c.props).forEach(child=>{
                if(child.props.pivot){
                    row.items??={};
                    let k = `${item[child.props.field]}$id_${item[child.props.idField]}`;
                    row=row.items[k]??={};

                }
                else{
                    row[child.props.column??child.props.field]=item[child.props.field];
                }
            });
            
        });
        if(items.length>0){
            var list = recursiveListify(groupedObj.items);
            setGroupedItems(list);
        }
        if(!loading){

            setTransforming(false);
        }

        
    }, [items])

    

    return <MultiDimTable autoExpand={autoExpand} filter={filter} loading={transforming} items={groupedItems} showIfChild={showIfChild} filterLevel={filterLevel} >
        {[<td field="items" nested/>,
         <td field="key" header="Dimension" className=" text-ellipsis overflow-hidden whitespace-nowrap" sortable/>].concat(children.filter(c=>c.props).filter(c=>!c.props.pivot&&!c.props.column)).map(({props}, index)=>{
            return <td key={index} field={props.field} header={props.header} nested={props.nested} display={props.display} sortable={props.sortable} className={props.className}/>
         })
        }
    </MultiDimTable>
        
        
    
}

export default PivotTable;