import React from "react"
import {Navigation, NavGroup, NavItem} from "./Navigation";
import SidebarHeader from "./SidebarHeader";
import Flask from "./svg/Flask";
import Home from "./svg/House";
import Link from "./svg/Link";
import People from "./svg/People";
import Suitcase from "./svg/Suitcase";
import Monitor from "./svg/Monitor";
import ClipboardCheck from "./svg/ClipboardCheck";
import Mail from "./svg/Mail";
import Report from "./svg/Report";
import Phone from "./svg/Phone";

class Sidebar extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            isOpen:this.props.isOpen,
            backgroundTransition: "open",
            ignoreUpdate: false
        }
    }

    backgroundClass = (state)=>{
      
        switch(state.backgroundTransition){
            case "open":
                return "opacity-100";
            case "closing":
                return "opacity-0";
            case "closed":
                return "translate-x-full opacity-0";
            case "opening":
                return "opacity-100" 
        }
    }
    close = () =>{
      
        if(this.state.backgroundTransition=="open"){
         
            this.setState({
                isOpen:this.props.isOpen,
                backgroundTransition: "closing"
            })
            setTimeout(()=>{
             
                this.setState({
                    backgroundTransition: "closed"
                })
            }, 150)
        }
    }
    open = () =>{
     
        if(this.state.backgroundTransition==="closed"){
        
            this.setState({
                isOpen:this.props.isOpen,
                backgroundTransition: "opening"
            })
            setTimeout(()=>{
           
                this.setState({
                    backgroundTransition: "open"
                })
            }, 150)
        }
    }
    
    componentDidUpdate(){
        if(this.state.isOpen===this.props.isOpen){
            return;
        }
        
        if(!this.props.isOpen){
            this.close();
        }else{
            this.open();
        }

      
    }



    render() {
        let auth;
        if(this.props.userInfo){
            auth=this.props.userInfo[0]?.customer;
        }

        var className=this.props.isOpen?"translate-x-0":"-translate-x-full rtl:lg:-translate-x-0 rtl:translate-x-full";
        return <>
        <div className={`fixed inset-0 z-20 w-full h-full bg-gray-900/50 lg:hidden transition-opacity ${this.backgroundClass(this.state)}`} onClick={this.props.onSidebarToggle}/>
        <aside className={`fixed inset-y-0 left-0 rtl:left-auto rtl:right-0 z-20 flex flex-col h-screen shadow-2xl transition-transform bg-dark-600 border-dark-300 lg:border-r w-80 lg:z-0 lg:translate-x-0 ${className}`}>
            <SidebarHeader onClose={this.props.onSidebarToggle}/>
            <Navigation>
                <NavGroup>
                    {
                        auth?.dashboard_url&&<NavItem id="dashboard" active={this.props.active==="dashboard"} icon={Monitor} href="dashboard" >
                        Dashboard
                        </NavItem>
                    }
                    {
                        auth?.dashboard_calls_url&&<NavItem id="calls" active={this.props.active==="calls"} icon={Phone} href="calls" >
                        Calls
                        </NavItem>
                    }
                    {
                        auth?.podio_id&&<NavItem id="projects" active={this.props.active==="projects"} icon={ClipboardCheck} href="projects" >
                        Projects
                        </NavItem>
                    }
                    {
                        (auth?.channel_li||auth?.channel_ga||auth?.channel_fb)&&<NavItem id="ads" active={this.props.active==="ads"} icon={Report} href="ads" >
                        Ads
                        </NavItem>
                    }
                    {
                        auth?.ac_api_url&&<NavItem id="email" active={this.props.active==="emails"} icon={Mail} href="emails" >
                        Emails
                        </NavItem>
                    }
                    <NavItem id="links" active={this.props.active==="links"||this.props.active==="linkedit"||this.props.active==="linkcreate"} icon={Link} href="links" >
                        Links
                    </NavItem>
                    
                </NavGroup>
                <NavGroup label="Other">
                    <NavItem active={this.props.active==="privacy"} id="privacy-policy" href="privacy">
                        Privacy Policy
                    </NavItem>
                </NavGroup>
            </Navigation>
        </aside>
        </>
    }
}

export default Sidebar;