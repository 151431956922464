import { useEffect, useState, useRef } from "react";
import useAuth from "../hooks/useAuth"
import useData from "../hooks/useData";
import { ArrowDown, ArrowUp } from "./svg/Arrow";
import useSwitch from "../hooks/useSwitch";
import X from "./svg/X";

const CustomerSwitch = ({selectedValue})=>{
    const [customers, refreshCustomers, isLoading] = useData([],"auth/customers", false);
    const [search, setSearch] = useState("");
    const [isSwitching, setSwitching] = useState(false);
    const [items, setItems] = useState();
    const [mouseOver, setMouseOver] = useState(false);
    const inputRef = useRef(null);

    const switchCustomer = useSwitch();

    useEffect(()=>{
        setSearch(selectedValue)

    },[selectedValue, isSwitching])

    useEffect(()=>{
        searchItems(search);
    },[search])
    useEffect(()=>{
        if(!isSwitching){
            setSearch(selectedValue)
        }
    },[isSwitching])

    const handleClick = (event)=>{
        event.target.select();
        setSearch("");
        setSwitching(true);
    }

    const searchItems = (value)=>{
        if(value&&value.trim()!=""){
            value=value.toLowerCase();
        setItems(customers.filter(customer=>customer.name.toLowerCase().includes(value)&&customer.name!=selectedValue));
        }else{
            setItems(customers);
        }

        
    }
    const handleEnter = (e)=>{
        if(e.key==="Enter"){
            if(items[0]){
                switchCustomer(items[0].id);
            }
        }
    }
    
    

    return <div>
        
        <div className="relative group max-w-md">
        <input ref={inputRef} className="p-0 border-none bg-transparent w-full focus:ring-0 cursor-pointer" onKeyDown={handleEnter} onFocus={handleClick} onClick={handleClick} onBlur={e=>{
            if(!mouseOver){
                setSwitching(false)
            }
        }} type="text" value={search} onChange={(e) => setSearch(e.target.value)}/>
        <span className="ml-auto absolute inset-y-0 right-0 flex items-center justify-center h-full w-4 text-gray-300 pointer-events-none">{isSwitching?<ArrowUp/>:<ArrowDown/>}</span>
        </div>
        {
                (items&&items.length>0&&isSwitching) &&
                <div  className="md:shadow-xl  absolute md:left-6 rtl:right-auto rtl:left-0 top-auto z-100 mt-2  overflow-hidden md:w-auto w-screen left-0 flex justify-center ">
                    <div onMouseEnter={()=>setMouseOver(true)} onMouseLeave={()=>setMouseOver(false)} className="md:w-auto md:h-auto w-2/3 overflow-hidden max-h-96 bg-dark-600 rounded-xl border border-dark-300 shadow-xl my-12 md:m-0">
                        <div className="px-6 py-2 bg-dark">
                        <span className="text-xs font-bold tracking-wider text-gray-300 uppercase">
                                        skift kunde
                                    </span>
                        </div>
                        <ul className="divide-y divide-dark-300 h-full overflow-y-scroll">
                            
                            {
                                items.map(item=>{
                                    return <li key={item.id}>
                                        <button onClick={e=>switchCustomer(item.id)} className="w-full text-left font-medium relative block px-3 py-2 focus:bg-gray-300/5 hover:bg-gray-300/5 focus:ring-1 focus:ring-dark-300">
                                        {item.name}
                                        </button>
                                    </li>
                                })
                            }
                        </ul>
                        
                    </div>
                </div>
            }
        
    </div>
}
const AdminSidebarHeader = ({customerName,customerId, onClose})=>{

    
    return <header className="border-b h-[4rem] border-dark-300 shrink-0 px-6 flex items-center  ">
   
    <div className="flex items-center w-full space-x-4 md:justify-start justify-between">
            <a href="/" className="w-4">
                <img src="/img/mindmill-icon-positive.svg" alt="Logo" className="h-8 w-4" />
            </a>
            <div className="">
                <CustomerSwitch selectedValue={customerName}/>

                
            </div>
            <button className="lg:hidden ml-auto text-primary-500" onClick={onClose}>
                <X className="h-6 w-6"/>
            </button>
        </div> 
       
      
        
</header>
    
}

export default function SidebarHeader({onClose}){
    const [auth, checkAuth] = useAuth();

    

    return <>
    {
        auth.admin||auth.multiple?<AdminSidebarHeader customerName={auth.customer.name} onClose={onClose}/>:
        <header className="border-b border-dark-300 h-[4rem] shrink-0 px-6 flex items-center justify-between">
        <a href="dashboard">
            <div className="flex items-center w-full mr-4">
                <div className="w-4">
                    <img src="/img/mindmill-icon-positive.svg" alt="Logo" className="h-8 w-4" />
                </div>
                <div className="ml-4 w-full">
                    {auth.customer.name}
                </div>
            </div>        
            </a>
            <button className="md:hidden pl-4 text-primary-500" onClick={onClose}>
                <X className="h-6 w-6"/>
            </button>
    </header>
}
    </>
}
