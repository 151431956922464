import { useEffect, useState } from "react";
import Text from "../components/form/Text";
import { btoa } from "react-native-quick-base64";
import axios from "axios";
import useData from "../hooks/useData";
import usePubSub from "../hooks/usePubSub";
import Switch from "../components/form/Switch";

const Lists = ({})=>{
    const [rows,] = useData([], "bigquery/imports");
    const [sendMessage] = usePubSub("Presales");


    const DisplayStatus = (row)=>{
        
        return <Switch value={row.status==="ENQUEUED"} onChange={(value)=>{
            let message = row;
            message["_CHANGE_TYPE"]="UPSERT";
            message["status"]=value?"ENQUEUED":"EXCLUDE";
            

            console.log(message);

            sendMessage(message);
        }}/>
    }


    return <div>
        <table>
            <thead>
                <tr>
                    <th className="border-white border-2">Id</th>
                    <th className="border-white border-2">importId</th>
                    <th className="border-white border-2">customerId</th>
                    <th className="border-white border-2">status</th>
                </tr>
            </thead>
            <tbody>
            {
                rows.map((row,index)=>{
                    return <tr key={index}>
                        <td className="border-white border-2">{row.ID}</td>
                        <td className="border-white border-2">{row.importId}</td>
                        <td className="border-white border-2">{row.customerId}</td>
                        <td className="border-white border-2">{DisplayStatus(row)}</td>
                    </tr>
                })
            }

        </tbody>
        </table>
        
    </div>
}

export default Lists;