import { useState } from "react";
import MessageBox from "./MessageBox";

const ConfirmationButton = ({className, title, content, onClick, children}) => {
    const [state, setState] = useState(false);

    const onHide = ()=>{
        setState(false);
    }

    const handleSubmit = (value) =>{
        if(value==="confirm"){
        onClick();
        }
    }

    return <>
    <button type="button" onClick={()=>setState(true)} className={className}>
        {children}
</button>
{
    state&&<MessageBox onHide={onHide} title={title} content={content} onSubmit={handleSubmit}/>
}
    </>;

    

}

export default ConfirmationButton;