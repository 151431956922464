import { useEffect, useState } from "react";

const Number = ({onChange, id, name, value, min, max, placeholder})=>{
    const [number, setNumber] = useState();
    useEffect(()=>{
        setNumber(parseInt(value));
    }, [value])

    const handleChange=(e)=>{
        let n = parseInt(e.target.value);
        setNumber(n);
        onChange(n);
    }

    return <input placeholder={placeholder} onChange={handleChange} id={id} type="number" min={min} max={max} name={name} value={number} className="bg-dark-600 block w-full transition duration-75 rounded-lg shadow-sm focus:border-primary-600 focus:ring-1 focus:ring-inset focus:ring-primary-600 disabled:opacity-70  border-dark-300"/>
}

export default Number;