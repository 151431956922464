import useData from "../hooks/useData";
import PivotTable from "../components/PivotTable";
import Link from "../components/svg/Link";
import DateFormat from "../components/DateFormat";
import Switch from "../components/form/Switch";
import { useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import useAuth from "../hooks/useAuth";
import Filter from "../components/Filter";
import MultiSelect from "../components/form/MultiSelect";

const LinkRow = (link)=><a href={link} target="_blank"><Link className="w-5 h-5"/></a>;


const StatusFilter = ({ onChange, value }) => {
    return <MultiSelect value={value} onChange={onChange} placeholder="All">
        <option value="ACTIVE">Active</option>
        <option value="DRAFT">Draft</option>
        <option value="ARCHIVED">Archived</option>
        <option value="PAUSED">Paused</option>
        <option value="REVIEW">Review</option>
        
    </MultiSelect>
}


const AdsFilter = ({ onChange }) => {
    const sParams = useSearchParams();
    return <Filter searchParams={sParams} onChange={onChange} filters={[
        {
            name: "status",
            element: <StatusFilter />,
            label: "Status",
            defaultValue: []
        }
    ]} />
}

const Status = (status) => {
    if(!status){
        return <></>
    }
    const className = 'capitalize inline-flex items-center justify-center h-6 xl:px-2 xl:py-0.5 w-6 xl:w-auto overflow-hidden whitespace-nowrap text-sm font-medium tracking-tight rounded-xl ';
    
    switch(status){

      
            case 'REVIEW':
            return <span className={`${className} text-primary-400 bg-primary-400/10`}><span className="hidden xl:inline">Review</span><div className="xl:hidden bg-primary-400 h-3 w-3 rounded-full"/></span>;
        case 'ACTIVE':
        case 'ENABLED':
        
             return <span className={`${className} text-success-400 bg-success-400/10`}><span className="hidden xl:inline">{status.toLowerCase()}</span><div className="xl:hidden bg-success-400 h-3 w-3 rounded-full"/></span>;
        
             
            
             default:
                return <span className={`${className} text-gray-400 bg-gray-400/10`}><span className="hidden xl:inline">{status.toLowerCase()}</span><div className="xl:hidden bg-gray-400 h-3 w-3 rounded-full"/></span>;
    }
    
 
}

const Ads = () => {
    const transformAds = (response, sfr = null)=>{
        sfr??=selectedForReview;
        return response.map(value=>{
            if(selectedForReview[value.ad_id]){
                if(value.status!="REVIEW"){
                    value.actualStatus=value.status;
                }
           
                value.status="REVIEW";
            } else if(value.status=="REVIEW"){
                value.status=value.actualStatus;
           
            }
                
                return value;
        });
    }
    const [ads,,loading] = useData([],"bigquery/ads",false)
    const [selectedForReview, setSelectedForReview] = useState({});
    const [searchParams, setSearchParams] = useSearchParams();
    const [transformedAds, setTransformedAds] = useState([]);
    const [userInfo,] = useAuth();

    useEffect(()=>{
        if(ads){
            setTransformedAds(transformAds(ads));
        }
    },[ads,selectedForReview])

    useEffect(()=>{
        let review = searchParams.get("review");
        if(review){
            let sfr = {};
            review.split(',').forEach(value=>{
                sfr[value]=true;
            })
            setSelectedForReview(sfr);
        }
    }, [searchParams])

    const updateParameter = (value)=>{
        if(ads){
            setTransformedAds(transformAds(ads, value));
        }
        
        let sfr = Object.entries(value).filter(([key,value])=>value).map(([key,value])=>key).join(',');
  
        let q = searchParams.get("q");
        if(sfr){
            if(q!="review"){
                searchParams.set("q", "review");
            }
            searchParams.set("review", sfr);
        }else if(q=="review"){
            searchParams.delete("q");
            searchParams.delete("review");
        } 
        setSearchParams(searchParams);
        
    }
    const ReviewColumn = (row)=>{
    
        const handleChange = (value)=>{
            let sfr = selectedForReview
            sfr[row]=value;
            setSelectedForReview(sfr);
            updateParameter(sfr);
        }
        return <Switch value={selectedForReview[row]} onChange={handleChange} />;
    }
   
    return <div>
        <PivotTable filter={<AdsFilter/>} autoExpand={(i)=>{ return i.status=="REVIEW";}} items={transformedAds} loading={loading} showIfChild={searchParams.get("q")==="review"} filterLevel={1}>
            <td field="source" idField="source"  pivot/>
            <td field="campaign_group_name" idField="campaign_group_id"  pivot/>
            <td field="campaign_group_start_date" column="campaign_start_date"/>
            <td field="campaign_group_end_date" column="campaign_end_date"/>
            <td field="campaign_group_status" column="status"/>
            <td field="campaign_name" idField="campaign_id" pivot/>
            <td field="campaign_objective_type"  className="hidden xl:flex" header="Objective"/>
            <td field="campaign_start_date"  className="hidden xl:flex" header="Start date" display={DateFormat}/>
            <td field="campaign_end_date"  className="hidden xl:flex" header="End date" display={DateFormat}/>
            <td field="campaign_status" column="status"/>
            <td field="ad_name" idField="ad_id" pivot/>
            <td field="ad_created_at" column="campaign_start_date"/>
            <td field="status" header="Status" className="col-start-8 col-end-9 xl:col-start-6 xl:col-end-7 flex flex-row-reverse pr-4" display={Status}/>
            
            <td field="preview" header="Preview" className=" h-0 xl:h-auto -my-3 xl:m-0 col-start-7 flex flex-row-reverse pr-4 col-end-8 xl:col-start-auto xl:col-end-auto -translate-y-8 xl:translate-y-0" display={LinkRow}/>
            
            {userInfo.admin&&<td field="ad_id" header="Set for review"  className="hidden xl:flex" display={ReviewColumn}/>}
        </PivotTable>
    </div>;
}
export default Ads;