import axiosClient from "../axiosClient";



let user;

const setUser = (u)=>user=u;


const useAuth = () =>{

  const checkAuth = (callback) => {
    axiosClient({
        url:"auth/customer/profile",
        method:"get"
    }).then(response=>{
        let data = response.data;
        switch(data.claims){
            case "employee":
            case "admin":
                data["admin"]=true;
                    break;
                    default:
                    data["admin"]=false;
                    break;
        }
        setUser(response.data);
        callback(response.data);
    }).catch(()=>{
        setUser(null);
        callback(null);
    })
               
  }


  return [user, checkAuth];
}

export default useAuth;