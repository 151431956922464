import { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";

const Unauthorized = ({}) =>{
    const [searchParams, setSearchParams] = useSearchParams();
    const [email, setEmail] = useState();

    useEffect(()=>{
        setEmail(searchParams.get("email"));
    },[searchParams]);

    return <div className="flex items-center justify-center w-screen h-screen flex-col space-y-2 md:space-y-6  text-center m-6">
        <h1 className=" font-bold text-lg md:text-3xl">Access denied</h1>
        <h2 className=" text-gray-300 text-xs md:text-xl ">
            Your account, <span className=" font-semibold">{email}</span>, does not have access to <span className=" font-semibold">mindmill.io</span>
        </h2>
        <div className="space-y-3">
        <a href="/login?prompt=select_account" className="text-xs md:text-lg inline-flex items-center justify-center font-medium tracking-tight rounded-lg border transition-colors focus:outline-none focus:ring-offset-2 focus:ring-2 focus:ring-inset h-9 px-4 shadow focus:ring-dark-900 border-transparent bg-primary-600 hover:bg-primary-500 focus:bg-primary-700 focus:ring-offset-primary-700 w-full">Switch account</a>
        <a href={`/request-access?email=${email}`} className="text-xs md:text-lg border-dark-300 inline-flex items-center justify-center font-medium tracking-tight rounded-lg border transition-colors focus:outline-none focus:ring-offset-2 focus:ring-2 focus:ring-inset h-9 px-4 shadow focus:ring-dark-900 bg-dark-900 hover:bg-gray-300/5 focus:bg-gray-300/5 focus:ring-offset-gray-300/5 w-full">Request Access</a>

        </div>
        </div>
}
export default Unauthorized;