import { useEffect } from "react";
import { DataTable } from "../components/DataTable"
import useData from "../hooks/useData";
import Filter from "../components/Filter";
import { useSearchParams } from "react-router-dom";
import Monitor from "../components/svg/Monitor";
import RangeFilter from "../components/RangeFilter";


const PresalesFilter = ({ onChange }) => {
    const sParams = useSearchParams();
    return <Filter searchParams={sParams} onChange={onChange} filters={[
        {
            name: "impressions",
            element: <RangeFilter/>,
            label: "Impressions",
            defaultValue: {type:"range"}
        },
        {
            name: "clicks",
            element: <RangeFilter/>,
            label: "Clicks",
            defaultValue: {type:"range"}
        },
        {
            name: "engagements",
            element: <RangeFilter/>,
            label: "Engagements",
            defaultValue: {type:"range"}
        }
    ]} />
}
const Presales = () => {
    
    return <DataTable filter={<PresalesFilter/>} readonly searchable="company_name" path="bigquery/presales">
        <td header="Company" field="company_name"/>
        <td row={(url)=>{
        if(url){
            return <a href={`https://${url}`} target="_blank"><Monitor className="w-6 h-6 text-gray-300" /></a>
        }else{
            return <></>
        }
    }} field="url" header="Website"/>
        <td header="Impressions" field="impressions" sortable/>
        <td header="Contacts" field="contacts" sortable/>
        <td header="Downloads" field="downloads" sortable/>
        <td header="Clicks" field="clicks" sortable/>
        <td header="Engagements" field="engagements" sortable/>
        <td header="Views" field="video_views" sortable/>
        
        
    </DataTable>
}
export default Presales;