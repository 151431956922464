import { useEffect, useState } from "react";
import OutsideAlerter from "../OutsideAlerter";

const TextWithSuggestions = ({onChange, value, name, id, suggestions, placeholder}) =>{

    const [showOptions, setShowOptions] = useState(false);
    const [options, setOptions] = useState([]);
    const [className, setClassName] = useState("opacity-0 hidden")
    const [text, setText] = useState();

    useEffect(()=>{
        setText(value);
        
    }, [value])

    

    const handleChange = (e)=>{
        if(onChange){
            onChange(e.target.value);
        }
        
        setText(e.target.value);
    }
    useEffect(()=>{
        if(showOptions){
            setClassName("opacity-0")
            setTimeout(()=>{
                setClassName("opacity-100")
            }, 1);
        } else{
            setClassName("opacity-0")
                setTimeout(()=>{
                    setClassName("opacity-0 hidden")
                }, 100);
                    
        }
        
    }, [showOptions])

    useEffect(()=>{
        let search = text?.toLowerCase()??"";
        setOptions(suggestions.filter(o=>o.toLowerCase().includes(search)));
    },[text, suggestions]);
    
    return<div className="block w-full transition duration-75 divide-y rounded-lg shadow-sm border focus-within:border-primary-600 focus-within:ring-1 focus-within:ring-primary-600 border-dark-300 divide-dark-300">

    <div className="relative">
     <OutsideAlerter onOutsideClick={()=>setShowOptions(false)}>
    <div className="relative rounded-lg overflow-hidden">
    <input value={text} id={id} autoComplete="off" onChange={handleChange} name={name} placeholder={placeholder} className="bg-dark-600 block w-full border-0" type="text"  onClick={e=>setShowOptions(!showOptions)} >
        
    </input>
   
    
   
    </div>
    <div className={`absolute z-10 w-full my-1 bg-dark-600 border border-dark-300 rounded-lg shadow-sm focus:outline-none transition duration-100 ease-in ${className}`} style={{bottom:"auto"}}>
        <ul className="py-1 overflow-auto text-base leading-6 max-h-60 focus:outline-none">
            {options.map((o, index)=>{
                
                let handleClick = () =>{
                    handleChange({
                        target:{
                            value:o
                        }
                    })
                    onChange(o);
                    setShowOptions(false)
                }
                

                return <li key={index} onClick={handleClick} className="relative py-2 pl-3 flex items-center cursor-default select-none pr-9 hover:text-white hover:bg-primary-500  group">
                    <span className="block truncate font-normal">
                        {o}
                    </span>
                </li>
            })}
        </ul>
    </div>
    </OutsideAlerter>
    </div>
    </div>
}
const Text = ({onChange, value, name, disabled, secret, id, suggestions, placeholder, required})=>{

    const [text, setText] = useState();

    useEffect(()=>{
        setText(value);
        
    }, [value])

    

    const handleChange = (e)=>{
        if(onChange){
            onChange(e.target.value);
        }
        
        setText(e.target.value);
    }

    if(suggestions){
        return <TextWithSuggestions placeholder={placeholder} onChange={onChange} value={value} name={name} disabled={disabled} suggestions={suggestions} id={id}/>
    }
    return <input required={required} placeholder={placeholder} disabled={disabled} id={id} name={name} value={text} type={secret?"password":"text"} className="text-gray-50 bg-dark-600 block w-full transition duration-75 rounded-lg shadow-sm focus:border-primary-600 focus:ring-1 focus:ring-inset focus:ring-primary-600 disabled:opacity-70 border-dark-300" data-value={text?true:false} onChange={handleChange}/>
}
export default Text;