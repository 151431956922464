import { useEffect, useState } from "react";
import useAuth from "../hooks/useAuth";
import Link from "../components/svg/Link";
import useLog from "../hooks/useLog";

const Dashboard = () =>{
    const [userInfo, checkAuth] = useAuth();
    const [embedCode, setEmbedCode] = useState(<></>);
    const [] = useLog("Dashboard", "Ads");

    useEffect(()=>{
        if(userInfo?.customer?.dashboard_url){
            let code = <>
            <div className="overflow-hidden " style={{height:"870px", width:"100%"}}>
            <iframe className="looker-embed -translate-x-[10%] lg:-translate-y-[7%] xl:-translate-y-[10%] -translate-y-[10%] z-[-10]" style={{height:"1080px", width:"125%"}} src={userInfo.customer.dashboard_url.replace(/(u\/0\/)?reporting/, "embed/reporting")}  allowFullScreen></iframe>
            </div>
            </>;
            setEmbedCode(code);
        }
    }, [userInfo]);

    return <div className="flex flex-col-reverse">
        {embedCode}
        
    </div>
}
const DashboardHeader = ({calls})=>{
    const [userInfo, checkAuth] = useAuth();

    console.log(calls);

    return <div className='flex justify-between'><span>{calls?"Calls":"Dashboard"}</span><a className="text-lg text-gray-300 flex align-middle" href={calls?userInfo.customer.dashboard_calls_url:userInfo.customer.dashboard_url} target="_blank">Go to dashboard <Link className="h-4 w-4 p-2 box-content" /></a></div>
}
export {DashboardHeader};
export default Dashboard;