import { useEffect, useRef, useState } from "react";
import ProgressBar from "../components/ProgressBar";
import { Link } from "react-router-dom";
import Text from "../components/form/Text";
import Select from "../components/form/Select";
import useData from "../hooks/useData";
import SolidPeople from "../components/svg/SolidPeople";
import useAuth from "../hooks/useAuth";
import X from "../components/svg/X";
import axiosClient from "../axiosClient";
import bytesToString from "../scripts/bytesToString";
import Check from "../components/svg/Check";
import ReviewRecords from "./ReviewRecords";
import { sendNotification } from "../components/Notification";


const Type = ({type,title,description,icon, typeState})=>{
    const [fileType, setFileType] = typeState;

    return <button onClick={()=>setFileType(type)} className={`p-4 w-full h-full grid grid-cols-2 rounded-xl transition-colors border-2 ${fileType===type?"bg-[#37302D] border-primary-700":"bg-dark-600 border-dark-300"}`} >
        <div className=" flex justify-start items-start">
            {icon}
        </div>
        <div className=" flex justify-end items-start">
            <div className={`w-5 h-5 items-center flex justify-center transition-colors rounded-full bg-dark-900 border-2 ${fileType===type?"border-primary-700":"border-dark-300"}`}>
            {fileType===type&&<div className="w-3 h-3 rounded-full bg-primary-500"/>}
            </div>
        </div>
        <div className=" col-span-2 text-left mt-4">
            <span className="text-sm font-semibold">
                {title}
            </span>
            <div className="text-xs text-gray-400">
                {description}
            </div>
        </div>
    </button>
}
const SelectType = ({typeState})=>{
    const [fileType, setFileType] = typeState;

    

    return <div className="grid lg:mb-0 lg:grid-cols-5 gap-4 w-full">
        <Type typeState={[fileType,setFileType]} icon={<img src="/img/leadinfo.svg" alt="Logo" className="h-8 w-8" />} title="Leadinfo" type="leadinfo" description="Follow up on company interest"/>
        <Type typeState={[fileType,setFileType]} icon={<img src="/img/dripify.svg" alt="Logo" className="h-8 w-8" />} title="Dripify" type="dripify" description="Call LinkedIn relations"/>
        <Type typeState={[fileType,setFileType]} icon={<img src="/img/linkedin.svg" alt="Logo" className="h-8 w-8" />} title="Demographics" type="demographics" description="Identify targeted contacts"/>
        <Type typeState={[fileType,setFileType]} icon={<img src="/img/cognism.svg" alt="Logo" className="h-8 w-8" />} title="Cognism" type="cognism" description="Import target prospects"/>
        <Type typeState={[fileType,setFileType]} icon={<img src="/img/other.svg" alt="Logo" className="h-8 w-8" />} title="Other" type="other" description="Import various lists"/>
    </div>
};

const UploadContacts = ({nameState,campaignState,fileState})=>{
    const [campaigns,] = useData([],"imports/campaigns");
    const [auth] = useAuth();
    const [fileName, setFileName] = useState();
    const inputFile =useRef();
    const [active, setActive] = useState(false);
    const [selectedCampaign, setSelectedCampaign] = campaignState;
    const [files, setFiles]=fileState;
    const [name, setName] = nameState;

    const ACCEPT = "text/csv, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/json, application/x-ndjson, application/json-lines, application/jsonl";

    useEffect(()=>{
        setFileName(files[0]?.name);
    }, [files])

    const selectFiles = (files)=>{
        if(!files[0]?.type?.match(/text\/csv|application\/vnd.ms-excel|application\/vnd.openxmlformats-officedocument.spreadsheetml.sheet|application\/xml|text\/xml|application\/json|application\/(x-ndjson|json-lines|jsonl)/))
        {
            sendNotification("danger", "File type not supported")
            return;
        }
        if(files[0].size>1073741824){
            sendNotification("danger", "File size cannot exceed 1GB")
            
            return;

        }
        setFiles(files);
    }

    const handleFileSelect = (event)=>{
        selectFiles(event.target.files);
        setActive(false);

    }
    const removeFile = (event)=>{
        setFiles([]);
    }
    const handleDragOver = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setActive(true);
      };
      const handleDrop = (e) => {

        const {files} = e.dataTransfer;
        selectFiles(files);
        setActive(false);

        e.preventDefault();
        e.stopPropagation();
      };
      const handleDragExit = (e)=>setActive(false);

    return <div className="grid lg:grid-cols-2 gap-6 lg:mb-0">
        <input files={files} accept={ACCEPT} type="file" className="hidden" ref={inputFile}  onChange={handleFileSelect} />

        <div className="lg:col-span-2 space-y-4">
            <span className="text-lg font-semibold">
                Contacts file
            </span>
            <div onDrop={handleDrop} onDragOver={handleDragOver} onDragEnd={handleDragExit} onDragLeave={handleDragExit} accept={ACCEPT} onClick={e=>{if(!fileName){inputFile.current.click()}}} className={`w-full cursor-pointer  h-32  border-2 border-dashed rounded-xl flex items-center justify-center text-gray-400 space-x-4 transition-colors ${active?"bg-[#37302D] border-primary-700":"bg-dark-600 border-dark-300"}`}>
            <SolidPeople className="w-12 h-12 "/>
            {
                fileName?
                <div className="  flex items-center ">
                    <div>
                    <span className="underline ">{fileName}</span>
                    <span className="block text-xs ml-2 text-gray-500">{files&&bytesToString(files[0]?.size)}</span>

                        </div>
                <button className="ml-3" onClick={removeFile}><X className="h-5 w-5"/></button></div>:
                <div className="text-center">Drag and drop or <span className="text-primary-500 underline">choose a file</span> to upload your Contacts.<br/> All .csv, .xlsx, and .xls file types are supported.</div>
            }
            </div>
        </div>

        <div className="space-y-3">
            <span className="text-sm font-semibold">
                Name your upload
            </span>
            <Text onChange={setName} name="uploadName" placeholder="Connections / BDR"/>
        </div>
        <div className="space-y-3">
            <span className="text-sm font-semibold">
                Select campaign
            </span>
            <Select onChange={setSelectedCampaign} placeholder={`${auth.customer.name} | Leads`} excludeDefault>
                {campaigns.map((campaign,index)=><option  value={campaign.campaignId} key={index}>{campaign.campaignName}</option>)}
            </Select>
        </div>
    </div>
}
const TypeMappings = {
    "cognism":{
        "Profile ID":{
            "Display": "Cognism ID",
            "FieldName":"crmCognismID"
        },
        "First Name":{
            "Display":"First Name",
            "FieldName": "FirstName"
        },
        "Last Name":{
            "Display":"Last Name",
            "FieldName": "LastName"
        },
        "Email":{
            "Display":"E-mail",
            "FieldName": "Email"
        },
        "Personal Linkedin URL":{
            "Display":"LinkedIn (personal)",
            "FieldName": "LinkedInPersonal"
        },
        "Company Linkedin URL":{
            "Display":"LinkedIn (company)",
            "FieldName": "LinkedInUrl"
        },
        "Company Name":{
            "Display":"Business",
            "FieldName": "Business"
        },
        "Job Title":{
            "Display":"Job Title",
            "FieldName": "JobTitle"
        },
        "Country":{
            "Display":"Country",
            "FieldName": "Country"
        },
        "City":{
            "Display":"City",
            "FieldName": "City"
        },
        "Mobile":{
            "Display":"Phone (mobile)",
            "FieldName": "phoneMobile"
        },
        "Direct":{
            "Display":"Phone (direct)",
            "FieldName": "phoneDirect"
        },
        "Office":{
            "Display":"Phone (office)",
            "FieldName": "phoneOffice"
        },
        "HQ":{
            "Display":"Phone (HQ)",
            "FieldName": "phoneHQ"
        },
        "Website":{
            "Display":"Website",
            "FieldName": "Website"
        },
        "Industries":{
            "Display":"Industry",
            "FieldName": "Industry"
        }
    },
    "demographics":{
        "domain":{
            "Display":"Website",
            "Fieldname":"Website"
        },
        "member_company":{
            "Display":"Business",
            "FieldName":"Business"
        },
        "clicks":{
            "Display":"Clicks",
            "FieldName":"Clicks"
        },
        "impressions":{
            "Display":"Impressions",
            "FieldName":"Impressions"
        },
        "company_profile":{
            "Display":"LinkedIn (company)",
            "FieldName":"LinkedinUrl"
        }
    },
    "leadinfo":{
        "Virksomhedsnavn":{
            "Display":"Business",
            "FieldName":"Business"
        },
        "Tags":{
            "Display":"CRM Segment",
            "FieldName":"crmSegment"
        },
        "Postnummer":{
            "Display":"ZIP",
            "FieldName":"ZIP"
        },
        "By":{
            "Display":"City",
            "FieldName":"City"
        },
        "Land":{
            "Display":"Country",
            "FieldName":"Country"
        },
        "Afdeling":{
            "Display":"Industry",
            "FieldName":"Industry"
        },
        "Reg nr.":{
            "Display":"CRM Vat",
            "FieldName":"crmVat"
        },
        "URL":{
            "Display":"Website",
            "FieldName":"Website"
        },
        "Telefonnummer":{
            "Display":"Phone (office)",
            "FieldName":"PhoneOffice"
        },
        "LinkedIn":{
            "Display":"LinkedIn (company)",
            "FieldName":"LinkedinUrl"
        },
        "E-mail":{
            "Display":"E-mail",
            "FieldName":"Email"
        }
    },
    "dripify":{
        "First name": {
            "Display": "First name",
            "FieldName": "FirstName"
        },
        "Last name": {
            "Display": "Last name",
            "FieldName": "LastName"
        },
        "LinkedIn email": {
            "Display": "E-mail",
            "FieldName": "Email"
        },
        "Corporate email": {
            "Display": "Corporate email",
            "FieldName": "EmailCorporate"
        },
        "Phone number": {
            "Display": "Phone number",
            "FieldName": "Phone"
        },
        "Title": {
            "Display": "Title",
            "FieldName": "JobTitle"
        },
        "Company": {
            "Display": "Business",
            "FieldName": "Business"
        },
        "Linkedin public url": {
            "Display": "LinkedIn url",
            "FieldName": "LinkedinUrl"
        },
        "Company website": {
            "Display": "Website",
            "FieldName": "Website"
        }
    }
    
    
}
const SelectMapping = ({defaultValue, fields, onChange})=>{
    const [value, setValue] = useState();
    const [examples, setExamples] = useState();

    
    useEffect(()=>{
        setValue(defaultValue);
        var examples = fields.find(([fieldName])=>fieldName.value===defaultValue)?.[1]?.value;

        setExamples(examples);
    },[defaultValue])

    const handleChange=(e)=>{
        setValue(e);
        var examples = fields.find(([fieldName])=>fieldName.value===e)?.[1].value;
        setExamples(examples);
        if(onChange){
            onChange(e);
        }
    }
    useEffect(()=>{
        console.log(value);
    },[value])
    return <>
    <td className="px-4 py-3"><Select value={value} onChange={handleChange}>
        {
            fields.map(([fieldName, value], index)=><option key={index} value={fieldName.value}>{fieldName.value}</option>)
        }
    </Select></td>
    <td className="px-4 py-3 text-[0.625rem] leading-3">{examples?.split("\n").map((value)=><div title={value} className=" min-w-56 overflow-ellipsis overflow-hidden whitespace-nowrap ">{value}</div>)}</td>
    <td className="px-4 py-3">{examples?
        <div className=" w-5 h-5 flex justify-center items-center bg-primary-500 text-white rounded-full"><Check className="w-3 h-3"/></div>:
        <div className=" w-5 h-5 flex justify-center items-center bg-gray-500 text-white rounded-full"><X className="w-3 h-3"/></div>
        }</td></>
    
}

const MapFields = ({fields, type, mappingState})=>{

    const [mappings, setMappings] = mappingState;

    useEffect(()=>{
        if(fields && type){
            let ob = {};

        fields.forEach(([fieldName, examples]) => {
            let mapping = TypeMappings[type][fieldName.value];
            if(mapping){
                ob[mapping.FieldName]=fieldName.value
            }
        });

        setMappings(ob);
        }
    }, [type,fields])


    const setMapping=(key,value)=>{
        var newMappings = mappings;

        newMappings[key]=value;

        setMappings(newMappings);

    }
    

    return <div className="bg-dark-600 rounded-xl shadow-sm border border-dark-300 w-full ">
        <table className="w-full">
        <thead className="border-b-2 border-dark-300">
            <tr>
                <th className="px-4 py-3 text-left">Adversus property</th>
                <th className="px-4 py-3 text-left">Header from file</th>
                <th className="px-4 py-3 text-left">Preview information</th>
                <th className="px-4 py-3 text-left">Mapped</th>
            </tr>
        </thead>
        <tbody className="font-normal text-gray-200 text-xs divide-dark-300 divide-y-2">
            {
                Object.entries(TypeMappings[type]).map(([FileHeader,{Display, FieldName}], index)=><tr key={index}>
                    <td className="px-4 py-3">{Display}</td>
                    <SelectMapping onChange={(v)=>setMapping(FieldName,v)} defaultValue={FileHeader} fields={fields} type={type}/>
                </tr>)
            }
        </tbody>
    </table>
    </div>
}

const CreateImport = ()=>{
    const [activeStep, setActiveStep] = useState(1);
    const [fileType, setFileType] = useState("dripify");
    const [selectedCampaign, setSelectedCampaign] = useState();
    const [files, setFiles]=useState([]);
    const [name, setName] = useState();
    const [upload, setUpload] = useState();
    const [loading, setLoading] = useState(false);
    const [mappings, setMappings] = useState({});
    const [queue, setQueue] = useState([]);

    const nextPage = async ()=>{
        switch(activeStep){
            default:
                setActiveStep(activeStep+1);
                break;
            case 2:
                if(name&&files[0]&&selectedCampaign){
                    await createUpload();
                    setActiveStep(activeStep+1);
                } else{
                    sendNotification("danger", "Form incomplete!")
                }
                break;
            case 3:
                await uploadMapping();
                setActiveStep(activeStep+1);
                break;
        }
    }
    const createUpload = async ()=>{
        setLoading(true);
        const file = files[0];
        const formData = new FormData();
        formData.append(`file0`,file,file.name);
        formData.append("uploadName", name);
        formData.append("campaignId", selectedCampaign);
        formData.append("sourceType", fileType);

        await axiosClient({
            url:"imports",
            method:"POST",
            data:formData
        }).then((response)=>{
            setUpload(response.data);
        })

    }

    const uploadMapping = async ()=>{

        let body={
            uploadId:upload.uploadId,
            mappings:mappings
        }

        await axiosClient({
            url:"imports/map",
            method:"POST",
            data:body
        }).then((response)=>{
            setQueue(response.data);
        })
    };

    const content = [
        {},
        {
            title:"What kind of data is in your file?",
            content:"Select one or more of the objects you want to create and associate. Understand how mindmill.io structures your data into objects using the data model overview tool"
        },
        {
            title:"Upload contacts",
            content:"Before you upload your files below, make sure your file is ready to be imported Learn how mimdmill.io knows when your records are duplicates."
        },
        {
            title:"Map columns in your file to contact properties",
            content:"Learn how to import records and activities."
        },
        {
            title:"Review before import",
            content:"Before you upload your files below, make sure your file is ready to be imported Learn how mimdmill.io knows when your records are duplicates."
        }
    ]

    return <div>
        <ProgressBar activeStep={activeStep} steps={[
  {
    label: 'Type',
    step: 1,
  },
  {
    label: 'Upload',
    step: 2,
  },
  {
    label: 'Map',
    step: 3,
  },
  {
    label: 'Preview',
    step: 4,
  }
]}/>

        <div className="mt-28 mb-16">
            <div className=" w-full flex justify-center">
                <div className="text-center max-w-xl space-y-4">
                    <h1 className=" text-2xl font-bold">{content[activeStep].title}</h1>
                    <p className=" text-sm text-gray-400">{content[activeStep].content}</p>
                </div>
            </div>
        </div>
        {
            activeStep==1&&<SelectType typeState={[fileType,setFileType]}/>
        }
        {
            activeStep===2&&<UploadContacts nameState={[name,setName]} campaignState={[selectedCampaign,setSelectedCampaign]} fileState={[files,setFiles]}/>
        }
        {
            activeStep===3&&<MapFields fields={upload.fields} type={fileType} mappingState={[mappings,setMappings]}/>
        }
        {
            activeStep===4&&<ReviewRecords uploadId={upload.uploadId}/>
        }
        <div className="h-20 "/>
        <div style={{
            boxShadow:"0px -3px 6px #00000029"
        }} className=" fixed bottom-0 w-full bg-dark-600 h-20 left-0 lg:pl-80 ">

            <div className=" w-full px-4 mx-auto md:px-6 lg:px-8 flex h-full items-center max-w-7xl"> 
                
                {activeStep===1?<Link relative="path" to=".."  className=" py-1 px-3 border-primary-600 hover:border-primary-500 transition-colors border-2 rounded-lg"> {'<'} Back</Link>:<button onClick={()=>{setActiveStep(activeStep-1)}} className=" py-1 px-3 border-primary-600 hover:border-primary-500 transition-colors border-2 rounded-lg"> {'<'} Back</button>}
                <Link relative="path" to=".."  className=" py-1 px-3 hover:underline">Cancel</Link>
                {activeStep===4?<Link relative="path" to=".." className="py-1 px-3 ml-auto hover:bg-primary-500 hover:border-primary-500 bg-primary-600 rounded-lg border-primary-600 transition-colors border-2">Add to queue</Link>:<button onClick={nextPage} className="hover:bg-primary-500 hover:border-primary-500 bg-primary-600 rounded-lg border-primary-600 transition-colors  py-1 px-3 ml-auto  border-2">Next {'>'}</button>}
            </div>

        </div>
    </div>


}

export default CreateImport;