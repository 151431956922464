
import { useEffect, useState } from 'react';
import { json, Link, useNavigate, useSearchParams } from 'react-router-dom';
import axiosClient from '../axiosClient';
import Cookies from 'js-cookie';
import useEncryptor from '../hooks/useEncryptor';
import { sha512 } from 'js-sha512';
import { atob, fromByteArray } from 'react-native-quick-base64';
  
const SwitchCustomer = ({ })=>{
    const [searchParams, setSearchParams] = useSearchParams();

    const customer=searchParams.get("customer");
    let navigate = useNavigate()

    useEffect(()=>{
        axiosClient({
            method: "GET",
            url: `/auth/customer/token/${customer}`
        })
            .then((response) => {
                
                if(response.data.success){
                    
                    Cookies.set('mm-customer-portal-token', response.data.token, {expires: 30});
                    navigate("../../dashboard");
                    window.location.reload(false);
                } 
                
            }).catch(()=>{});
    },[]);
    return <></>;

}
const Login = ({ }) => {
    const [query,] = useSearchParams(); 

    let rd =query.get("rd");
    let prompt = query.get("prompt");

    window.location.replace( `https://accounts.google.com/o/oauth2/v2/auth?client_id=708729931635-0p53d04d54919vnaau0ob0tmrs8das85.apps.googleusercontent.com&scope=profile%20openid%20email&response_type=code&redirect_uri=https://${window.location.host}/auth/google-callback&state=${rd}${prompt?`&prompt=${prompt}`:""}`);
    return <></>
    
   
}

export {SwitchCustomer};

export default Login;
