const Title = ({title, children, buttons})=>{
    return <div className="space-y-6">
                <div id="titleHeader" className="space-y-2 items-start justify-between flex sm:space-y-0 sm:space-x-4 sm:py-4">
                    <h1 className="w-full text-2xl font-bold tracking-tight md:text-3xl">{title}</h1>
                    {buttons&&<div className="flex flex-wrap items-center gap-4 justify-start shrink-0">
                        {buttons}</div>}
                </div>
                <div className="content-wrapper">
                    {children}
            </div>
            </div>
}

export default Title;