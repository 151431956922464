import { useState } from "react";
import OutsideAlerter from "./OutsideAlerter";

const Popup = ({children, icon, className, buttonClass, buttonStyle})=>{
    const [stage, setStage] = useState("closed");
    const [tclassName, setClassName] = useState("-translate-y-1 opacity-0 hidden");

    buttonClass??="flex items-center justify-center w-10 h-10 rounded-full hover:bg-gray-300/5 focus:outline-none  text-primary-500 focus:bg-primary-300/10";

    const changeState = (state)=> {
        switch (stage) {
            case "open":
                setStage("transition")
                setClassName("-translate-y-1 opacity-0")
                setTimeout(() => {
                    setStage("closed")
                    setClassName("-translate-y-1 opacity-0 hidden")
                }, 150)
                break;
            case "transition":
                break;
            case "closed":
                if(state!="close"){
                    setStage("transition")
                    setClassName("-translate-y-1 opacity-0")
                    setTimeout(() => {

                    setClassName("-translate-y-0 opacity-100")
                }, 1)
                    setTimeout(() => {
                    setStage("open")
                    setClassName("-translate-y-0 opacity-100")
                }, 150)
                }
                break;
        }
    }

    

    return <OutsideAlerter  className="relative inline-block shrink-0" onOutsideClick={()=>changeState("close")}>
        <button type="button" onClick={changeState} className={buttonClass} style={buttonStyle}>
            {icon}
        </button>
        
        <div className={`transition absolute z-10 mt-2 ${className} ${tclassName}`}>
                {children}
            </div>
    </OutsideAlerter>

}

export default Popup;