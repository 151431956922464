import { useEffect, useState } from "react";

const NotificationBar = ({}) => {

    

    const [message, setMessage] = useState([]);

    const addMessage = (msg) => {
        let newMessages = message.concat(msg);
        sessionStorage.setItem("notification", JSON.stringify(newMessages));
        setMessage(newMessages);

        setTimeout(()=>{

            let messages = message;
            messages.pop();
            sessionStorage.setItem("notification", JSON.stringify(messages));
            setMessage(messages);
        }, 6510);
    }

    useEffect(()=>{
        var jsonNotification = sessionStorage.getItem("notification");
        sessionStorage.removeItem("notification");
        if(jsonNotification){
            var notifications = JSON.parse(jsonNotification);
            if(Array.isArray(notifications)){
                notifications.forEach(addMessage);
            }
        }
    }, [])

    window.addEventListener("message", (e)=>{
        if(e?.data?.type!=="notification"){
            return;
        }
        
        sessionStorage.setItem("notification", JSON.stringify(e.data))
        addMessage(e.data);

        
    }, true);
   



    return <div className="fixed inset-x-0 top-0 z-50 p-3 pointer-events-none space-y-4">
        {
            Array.isArray(message)&&<>
            {
                message.map((msg, index)=><Notification key={index} level={msg.level}>
                {msg.content}
            </Notification>)
            }
            </>
        }
        
    </div>
}
const Notification = ({level, children}) => {
    const [transitionState, setTransitionState] = useState("scale-0 opacity-0");
    const [isShown, setIsShown] = useState(true);

    useEffect(()=>{
        setTimeout(()=>{
            setTransitionState("scale-100 opacity-100");
        }, 10)
        setTimeout(()=>{
            setTransitionState("scale-0 opacity-0");
        }, 6000)
        setTimeout(()=>{
            setIsShown(false);
        }, 6400)
    }, [])
    
    let iconColor;
    let textColor;
    let bgColor;
    let icon;

    switch(level){
        case "danger":
            iconColor="text-danger-600";
            textColor="text-danger-900";
            bgColor="bg-danger-50/50 ring-danger-200";
            icon = <svg className="shrink-0 w-6 h-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"></path>
                    </svg>
            break;
        case "success":
            iconColor="text-success-600";
            textColor="text-success-900";
            bgColor="bg-success-50/50 ring-success-200";
            icon = <svg className="shrink-0 w-6 h-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"></path>
          </svg>
            break;
        case "warning":
            iconColor="text-warning-600";
            textColor="text-warning-900";
            bgColor="bg-warning-50/50 ring-warning-200";
            icon = <svg className="shrink-0 w-6 h-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"></path>
          </svg>
            break;
        default:
            iconColor="text-primary-600";
            textColor="text-gray-900";
            bgColor="bg-primary-50/50 ring-primary-200";
            icon = <svg className="shrink-0 w-6 h-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path>
          </svg>
            break;
    }
    if(!isShown){
        return <div className="hidden"></div>
    }
    return <div className={`transition flex flex-col h-auto sm:max-w-xs max-w-screen mx-auto space-y-2 pointer-events-auto ${transitionState} ${iconColor}`}>
        <div className={`flex items-start px-3 py-2 space-x-2 backdrop-blur-xl backdrop-saturate-150 rtl:space-x-reverse text-xs shadow ring-1 rounded-xl ${bgColor}`}>
            {icon}
            <div className="flex-1 space-y-1">
                <div className="flex items-center justify-between font-medium">
                    <p className={`text-sm leading-6 ${textColor}`}>
                        {children}
                    </p>
                </div>
            </div>
        </div>
    </div>
}
function sendNotification(level, content){
    window.postMessage({
        type:"notification", 
        level:level.toLowerCase(),
        content:content
    });
}

export {sendNotification};
export default NotificationBar;