import { useState, useEffect, useRef } from "react";
import {convertToRaw,
    convertFromRaw,
    CompositeDecorator,
    ContentState,
    
    EditorState,
    RichUtils} from 'draft-js';
    
import Editor from "@draft-js-plugins/editor";

import 'draft-js/dist/Draft.css';
import addLinkPluginPlugin from "./plugins/addLinkPlugin";

const RichText = ({onChange, value, name, disabled, id, placeholder, readOnly, className})=>{

    const [text, setText] = useState();
    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    const [url, setUrl] = useState();
    const plugins=  [addLinkPluginPlugin]; 
    const buttonClass="h-full border-dark-300 bg-dark-600 text-gray-50 text-sm py-1 px-3 cursor-pointer font-medium border rounded-lg transition duration-200 shadow-sm hover:bg-dark-600 focus:ring-primary-200 focus:ring focus:ring-opacity-50"

    

   
    useEffect(()=>{
        if(readOnly&&value||value&&value!=text){
            let contentString = atob(value);
            setEditorState(EditorState.createWithContent(convertFromRaw(JSON.parse(contentString))));
        } else if(!value){
            setEditorState(EditorState.createEmpty());
        }
    }, [value])

    const handleChange = (e)=>{
        setEditorState(e);
        let rawString = btoa(JSON.stringify(convertToRaw(e.getCurrentContent())));
        if(onChange){
            onChange(rawString);
            setText(rawString);
        }
        
    }
    
    const onAddLink = () => {
        const selection = editorState.getSelection();
        const link = window.prompt('Paste the link -')
        if (!link) {
          handleChange(RichUtils.toggleLink(editorState, selection, null));
          return 'handled';
        }
        const content = editorState.getCurrentContent();
        const contentWithEntity = content.createEntity('LINK', 'MUTABLE', { url: link });
        const newEditorState = EditorState.push(editorState, contentWithEntity, 'create-entity');
        const entityKey = contentWithEntity.getLastCreatedEntityKey();
        handleChange(RichUtils.toggleLink(newEditorState, selection, entityKey))
    }
    const handleKeyCommand = command => {
        const newState = RichUtils.handleKeyCommand(
          editorState,
          command
        );
        if (newState) {
          handleChange(newState);
          return "handled";
        }
        return "not-handled";
      };
  
    const setState = (e, state)=>{
        e.preventDefault();
        handleChange(RichUtils.toggleInlineStyle(editorState, state))
    }
    return <div className="space-y-2">
        {/* Toolbar */}
        {
            !readOnly&&<div className="flex justify-between space-x-4 rtl:space-x-reverse  items-stretch">
            <div className="flex items-stretch space-x-4 rtl:space-x-reverse focus:outline-none">
                <div className="flex items-stretch space-x-1 rtl:space-x-reverse">
                <button type="button" onClick={e=>setState(e,"BOLD")} className={buttonClass}>
                    <svg className="h-4" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="bold" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path fill="currentColor" d="M321.1 242.4C340.1 220.1 352 191.6 352 160c0-70.59-57.42-128-128-128L32 32.01c-17.67 0-32 14.31-32 32s14.33 32 32 32h16v320H32c-17.67 0-32 14.31-32 32s14.33 32 32 32h224c70.58 0 128-57.41 128-128C384 305.3 358.6 264.8 321.1 242.4zM112 96.01H224c35.3 0 64 28.72 64 64s-28.7 64-64 64H112V96.01zM256 416H112v-128H256c35.3 0 64 28.71 64 63.1S291.3 416 256 416z"></path></svg>
                </button>
                <button type="button" onClick={e=>setState(e,"ITALIC")} className={buttonClass}>
                    <svg className="h-4" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="italic" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path fill="currentColor" d="M384 64.01c0 17.69-14.31 32-32 32h-58.67l-133.3 320H224c17.69 0 32 14.31 32 32s-14.31 32-32 32H32c-17.69 0-32-14.31-32-32s14.31-32 32-32h58.67l133.3-320H160c-17.69 0-32-14.31-32-32s14.31-32 32-32h192C369.7 32.01 384 46.33 384 64.01z"></path></svg>
                </button>
                <button type="button" onClick={e=>setState(e,"STRIKETHROUGH")} className={buttonClass}>
                    <svg className="h-4" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="strikethrough" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M332.2 319.9c17.22 12.17 22.33 26.51 18.61 48.21c-3.031 17.59-10.88 29.34-24.72 36.99c-35.44 19.75-108.5 11.96-186-19.68c-16.34-6.686-35.03 1.156-41.72 17.53s1.188 35.05 17.53 41.71c31.75 12.93 95.69 35.37 157.6 35.37c29.62 0 58.81-5.156 83.72-18.96c30.81-17.09 50.44-45.46 56.72-82.11c3.998-23.27 2.168-42.58-3.488-59.05H332.2zM488 239.9l-176.5-.0309c-15.85-5.613-31.83-10.34-46.7-14.62c-85.47-24.62-110.9-39.05-103.7-81.33c2.5-14.53 10.16-25.96 22.72-34.03c20.47-13.15 64.06-23.84 155.4 .3438c17.09 4.531 34.59-5.654 39.13-22.74c4.531-17.09-5.656-34.59-22.75-39.12c-91.31-24.18-160.7-21.62-206.3 7.654C121.8 73.72 103.6 101.1 98.09 133.1C89.26 184.5 107.9 217.3 137.2 239.9L24 239.9c-13.25 0-24 10.75-24 23.1c0 13.25 10.75 23.1 24 23.1h464c13.25 0 24-10.75 24-23.1C512 250.7 501.3 239.9 488 239.9z"></path></svg>
                </button>
                
                <button type="button" onClick={onAddLink} className={buttonClass}>
                    <svg className="h-4" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="link" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><path fill="currentColor" d="M598.6 41.41C570.1 13.8 534.8 0 498.6 0s-72.36 13.8-99.96 41.41l-43.36 43.36c15.11 8.012 29.47 17.58 41.91 30.02c3.146 3.146 5.898 6.518 8.742 9.838l37.96-37.96C458.5 72.05 477.1 64 498.6 64c20.67 0 40.1 8.047 54.71 22.66c14.61 14.61 22.66 34.04 22.66 54.71s-8.049 40.1-22.66 54.71l-133.3 133.3C405.5 343.1 386 352 365.4 352s-40.1-8.048-54.71-22.66C296 314.7 287.1 295.3 287.1 274.6s8.047-40.1 22.66-54.71L314.2 216.4C312.1 212.5 309.9 208.5 306.7 205.3C298.1 196.7 286.8 192 274.6 192c-11.93 0-23.1 4.664-31.61 12.97c-30.71 53.96-23.63 123.6 22.39 169.6C293 402.2 329.2 416 365.4 416c36.18 0 72.36-13.8 99.96-41.41L598.6 241.3c28.45-28.45 42.24-66.01 41.37-103.3C639.1 102.1 625.4 68.16 598.6 41.41zM234 387.4L196.1 425.3C181.5 439.1 162 448 141.4 448c-20.67 0-40.1-8.047-54.71-22.66c-14.61-14.61-22.66-34.04-22.66-54.71s8.049-40.1 22.66-54.71l133.3-133.3C234.5 168 253.1 160 274.6 160s40.1 8.048 54.71 22.66c14.62 14.61 22.66 34.04 22.66 54.71s-8.047 40.1-22.66 54.71L325.8 295.6c2.094 3.939 4.219 7.895 7.465 11.15C341.9 315.3 353.3 320 365.4 320c11.93 0 23.1-4.664 31.61-12.97c30.71-53.96 23.63-123.6-22.39-169.6C346.1 109.8 310.8 96 274.6 96C238.4 96 202.3 109.8 174.7 137.4L41.41 270.7c-27.6 27.6-41.41 63.78-41.41 99.96c-.0001 36.18 13.8 72.36 41.41 99.97C69.01 498.2 105.2 512 141.4 512c36.18 0 72.36-13.8 99.96-41.41l43.36-43.36c-15.11-8.012-29.47-17.58-41.91-30.02C239.6 394.1 236.9 390.7 234 387.4z"></path></svg>
                </button>
                   
                    
                </div>
            </div>
        </div>
        }

        {/* Textbox */}

        <div className={className??"text-gray-50 cursor-text bg-dark-600 border block w-full overflow-hidden resize-y transition duration-75 rounded-lg shadow-sm focus:border-primary-600 focus:ring-1 focus:ring-inset focus:ring-primary-600 disabled:opacity-70 border-dark-300 py-2 px-3 h-[122px]"}>


<Editor readOnly={readOnly} plugins={plugins} editorState={editorState} placeholder={placeholder} disabled={disabled} id={id} name={name} handleKeyCommand={handleKeyCommand} onChange={handleChange} />

</div>
    </div>
    return 
   
}



export default RichText;