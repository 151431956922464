import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import Text from "../components/form/Text";
import { BoldLabel } from "../components/form/Label";
import LabelInputPair from "../components/form/LabelInputPair";
import axiosClient from "../axiosClient";
import { sendNotification } from "../components/Notification";

const RequestAccess = ({}) =>{
    const [searchParams, setSearchParams] = useSearchParams();
    const [fullname, setFullname] = useState();
    const [email, setEmail] = useState();
    const [company, setCompany] = useState();

    useEffect(()=>{
        setEmail(searchParams.get("email"));
    },[searchParams]);
    
    const handleSubmit = (event) =>{
        event.preventDefault();
        axiosClient({
            method:"POST",
            url:`auth/request?fullname=${fullname}&email=${email}&company=${company}`
        }).then(response=>{
            sendNotification("success", "Request sent!")
        }).catch(()=>{})
    }


    return <div className="flex items-center justify-center w-screen h-screen">
        <form method="none" onSubmit={handleSubmit} className="space-y-6">
        <LabelInputPair label="Full Name *" name="fullname" >
            <Text required onChange={setFullname} value={fullname}/>
            </LabelInputPair>
            <LabelInputPair label="Email *" name="email" >
            <Text required onChange={setEmail} value={email}/>
            </LabelInputPair>
            <LabelInputPair label="Company *" name="company" >
            <Text required onChange={setCompany} value={company}/>
            </LabelInputPair>
            <button type="submit" className="inline-flex items-center justify-center font-medium tracking-tight rounded-lg border transition-colors focus:outline-none focus:ring-offset-2 focus:ring-2 focus:ring-inset h-9 px-4 shadow focus:ring-dark-900 border-transparent bg-primary-600 hover:bg-primary-500 focus:bg-primary-700 focus:ring-offset-primary-700 w-full">Request Access</button>
        </form>
    </div>

}
export default RequestAccess;