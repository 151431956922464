import { useEffect, useState } from "react";
import useData from "../hooks/useData";
import PivotTable from "../components/PivotTable";
import DateFormat from "../components/DateFormat";
import Link from "../components/svg/Link";
import Filter, { DateRangeFilter } from "../components/Filter";
import MultiSelect from "../components/form/MultiSelect";
import DatePicker from "../components/form/DatePicker";
import useAuth from "../hooks/useAuth";
import { useSearchParams } from "react-router-dom";
import Switch from "../components/form/Switch";

const LinkRow = (link) => <a href={link} target="_blank"><Link className="w-5 h-5" /></a>;
const Status = (status) => {
    if (!status) {
        return <></>
    }
    const className = 'inline-flex items-center justify-center h-6 xl:px-2 xl:py-0.5 w-6 xl:w-auto overflow-hidden whitespace-nowrap text-sm font-medium tracking-tight rounded-xl ';
    

    switch (status) {
        case 'Active':
        case 'Completed':
            return <span className={`${className} text-success-400 bg-success-400/10`}><span className="hidden xl:inline">{status}</span><div className="xl:hidden bg-success-400 h-3 w-3 rounded-full"/></span>
            case 'REVIEW':
                return <span className={`${className} text-primary-400 bg-primary-400/10`}><span className="hidden xl:inline">Review</span><div className="xl:hidden bg-primary-400 h-3 w-3 rounded-full"/></span>;
        case 'Stopped':
        case 'Inactive':
            return <span className={`${className} text-danger-400 bg-danger-400/10`}><span className="hidden xl:inline">{status}</span><div className="xl:hidden bg-danger-400 h-3 w-3 rounded-full"/></span>;
        case 'Paused':
            return <span className={`${className} text-warning-400 bg-warning-400/10`}><span className="hidden xl:inline">{status}</span><div className="xl:hidden bg-warning-400 h-3 w-3 rounded-full"/></span>;
        case 'Draft':
        default:
            return <span className={`${className} text-gray-400 bg-gray-400/10`}><span className="hidden xl:inline">{status}</span><div className="xl:hidden bg-gray-400 h-3 w-3 rounded-full"/></span>;
        case 'Sending':
        case 'Scheduled':
            return <span className={`${className} text-blue-400 bg-blue-400/10`}><span className="hidden xl:inline">{status}</span><div className="xl:hidden bg-blue-400 h-3 w-3 rounded-full"/></span>;
    }


}

const StatusFilter = ({ onChange, value }) => {
    return <MultiSelect value={value} onChange={onChange} placeholder="All">
        <option value="Draft">Draft</option>
        <option value="Scheduled">Scheduled</option>
        <option value="Sending">Sending</option>
        <option value="Paused">Paused</option>
        <option value="Stopped">Stopped</option>
        <option value="Completed">Completed</option>
        <option value="Active">Active</option>
        <option value="Inactive">Inactive</option>
        <option value="REVIEW">Review</option>
    </MultiSelect>
}


const EmailFilter = ({ onChange }) => {
    const sParams = useSearchParams();
    return <Filter searchParams={sParams} onChange={onChange} filters={[
        {
            name: "status",
            element: <StatusFilter />,
            label: "Status",
            defaultValue: []
        },
        {
            name: "send_date",
            element: <DateRangeFilter />,
            label: "Last send",
            defaultValue: {type:"dateRange"}
        }
    ]} />
}

const Emails = () => {
    const transformEmails = (response, sfr = null)=>{
        sfr??=selectedForReview;
        return response.map(value=>{
            if(selectedForReview[value.campaign_id]){
                if(value.status!="REVIEW"){
                    value.actualStatus=value.status;
                }
           
                value.status="REVIEW";
            } else if(value.status=="REVIEW"){
                value.status=value.actualStatus;
                
            }
            
                
                return value;
        });
    }
    const [emails,,loading] = useData([], "bigquery/emails", false)
    const [selectedForReview, setSelectedForReview] = useState({});
    const [searchParams, setSearchParams] = useSearchParams();
    const [transformedEmails, setTransformedEmails] = useState([]);
    const [userInfo,] = useAuth();

    useEffect(()=>{
        if(emails){
            setTransformedEmails(transformEmails(emails));
        }
    },[emails,selectedForReview])

    useEffect(()=>{
        let review = searchParams.get("review");
        if(review){
            let sfr = {};
            review.split(',').forEach(value=>{
                sfr[value]=true;
            })
            setSelectedForReview(sfr);
        }
    }, [searchParams])

    const updateParameter = (value)=>{
        if(emails){
            setTransformedEmails(transformEmails(emails, value));
        }
        
        let sfr = Object.entries(value).filter(([key,value])=>value).map(([key,value])=>key).join(',');
  
        let q = searchParams.get("q");
        if(sfr){
            if(q!="review"){
                searchParams.set("q", "review");
            }
            searchParams.set("review", sfr);
        }else if(q=="review"){
            searchParams.delete("q");
            searchParams.delete("review");
        } 
        setSearchParams(searchParams);
        
    }
    const ReviewColumn = (row)=>{
    
        const handleChange = (value)=>{
            let sfr = selectedForReview
            sfr[row]=value;
            setSelectedForReview(sfr);
            updateParameter(sfr);
        }
        return <Switch value={selectedForReview[row]} onChange={handleChange} />;
    }

    return <div>
        <PivotTable autoExpand={(i)=>{ return i.status=="REVIEW";}} filter={<EmailFilter />} items={transformedEmails} loading={loading} showIfChild={searchParams.get("q")==="review"} filterLevel={1}>
            <td field="dimension" pivot />
            <td field="mailflow" pivot />
            <td field="workflow_status" column="status" />
            <td field="campaign" pivot />
            <td field="send_date" header="Last send" className="hidden xl:flex" display={DateFormat} />
            <td field="last_updated" header="Last updated" className="hidden xl:flex" display={DateFormat} />
            <td field="status" header="Status" className="col-start-7 col-end-8 xl:col-start-auto xl:col-end-auto flex flex-row-reverse pr-4 xl:pr-2 xl:flex-row" display={Status} />
            <td field="preview" header="Preview" className="h-0 xl:h-auto -my-3 xl:m-0 col-start-6 col-end-7 flex flex-row-reverse pr-4 xl:pr-2 xl:col-start-auto xl:col-end-auto -translate-y-8 xl:translate-y-0" display={LinkRow} />
            {userInfo.admin&&<td field="campaign_id" header="Set for review" display={ReviewColumn} className="hidden xl:flex"/>}


        </PivotTable>
    </div>;
}
export default Emails;