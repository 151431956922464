import { useState } from "react";
import axiosClient from "../axiosClient";
import ConfirmationButton from "../components/ConfirmationButton";
import { sendNotification } from "../components/Notification";
import ReviewRecords from "./ReviewRecords"

const Imports = ({})=>{
    const [loading, setLoading] = useState(false);
    
    const handleConfirm = ()=>{
        setLoading(true);
        axiosClient({
            method:"POST",
            url:"imports/approve"
        }).then(()=>{
            setLoading(false);
            sendNotification("success", "WOOOHOOOO!!!!")
            console.log("Success");
        }).catch(()=>{
            setLoading(false);
            
        })
    };
    return <div className="space-y-6">
        <ReviewRecords/>
        <div className="flex justify-end">

        <button onClick={handleConfirm} className=" py-1 px-3 border-primary-600 hover:border-primary-500 transition-colors border-2 rounded-lg">Approve queue</button>
        </div>
    </div>
}
export default Imports;