import Card from "../components/basic/Card";
import useLog from "../hooks/useLog";

const PrivacyAnonymous = () => {
    return <main className="flex-1 w-full px-4 mx-auto md:px-6 lg:px-8  max-w-7xl">
        <div className="space-y-6">
            <div className="space-y-2 items-start justify-between sm:flex sm:space-y-0 sm:space-x-4 sm:py-4">
                <h1 className="w-full text-2xl font-bold tracking-tight md:text-3xl">Privacy</h1></div><div className="content-wrapper">
                    <Privacy/>
                    </div></div></main>
}

const Privacy = () => {
    const [] = useLog("Privacy", "Get");

    return <div className="space-y-12 ">
        <p>Thank you for visiting our website. This privacy policy outlines how we collect, use, and protect your personal information on this site. By using our website, you consent to the collection and use of your personal information as described in this policy.</p>

<div ><h3 className=" text-lg font-medium">Information we collect</h3>
<p className="text-gray-300">We collect certain information about you when you use our website. This includes your User Id, referring/exit pages, and date/time stamp.</p> </div>

<div ><h3 className=" text-lg font-medium">Use of information</h3>
<p className="text-gray-300">We use the information we collect to understand how our website is being used and to improve our services. We may use this information to analyze trends, administer the site, track users' movements around the site, and gather demographic information about our user base as a whole.</p></div>

<div ><h3 className=" text-lg font-medium">Cookies</h3>
<p className="text-gray-300">We use cookies to store user details such as User Id, Customer Id and email. A cookie is a small file that is stored on your computer or mobile device. We use cookies to remember your preferences, personalize content, and analyze our traffic. You can control the use of cookies through your browser settings.</p></div>

<div ><h3 className=" text-lg font-medium">Third-party links</h3>
<p className="text-gray-300">Our website may contain links to other websites. We are not responsible for the privacy practices or the content of these other websites. We encourage you to read the privacy policies of these other websites before providing any personal information.</p></div>

<div ><h3 className=" text-lg font-medium">Security</h3>
<p className="text-gray-300">We take reasonable measures to protect your personal information from unauthorized access, disclosure, or use. However, no transmission of data over the internet can be guaranteed to be completely secure.</p></div>

<div ><h3 className=" text-lg font-medium">Changes to this policy</h3>
<p className="text-gray-300">We reserve the right to modify this privacy policy at any time. Any changes to this policy will be posted on this page.</p></div>

<p className="text-gray-300">If you have any questions about this privacy policy, please contact us.</p>
    </div>
}

export default Privacy;
export {PrivacyAnonymous};