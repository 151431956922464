import { useState, useEffect } from "react"
import React from "react";

const SwitchLabel = ({children, name, help, label}) => {
    return <div className="flex items-center">
        <div className="h-5 flex items-center">
            {React.cloneElement(children, {
                name:name
            })}
        </div>
        <div className="ml-3 ">
            <label htmlFor={name} className="text-sm font-bold text-gray-700">
                {label}
            </label>
            {
                help&&<p className="text-xs text-gray-500">
                {help}
            </p>
            }
            
        </div>
    </div>
}
const Switch = ({onChange, value, name}) => {
    const [boolean, setBoolean] = useState(false);

    useEffect(()=>{
        setBoolean(value);
    }, [value])
   

    function switchValue() {
        setBoolean(!boolean);
        onChange(!boolean);
    }
    


    var buttonClass = boolean ? "relative inline-flex shrink-0 h-min w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:border-primary-500 focus:ring-2 focus:ring-primary-500 border-dark-300 bg-primary-600" : "relative inline-flex shrink-0 h-min w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:border-primary-500 focus:ring-2 focus:ring-primary-500 border-gray-300 bg-dark-900";
    return <button data-value={boolean} id={name} role="switch" name={name} type="button" onClick={switchValue} className={buttonClass}>
    <span className={`pointer-events-none relative inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 ease-in-out transition duration-200 ${boolean ? "translate-x-full rtl:-translate-x-full" : "translate-x-0 "}`}>
        <span className="absolute inset-0 h-full w-full flex items-center justify-center transition-opacity opacity-0 ease-out duration-100" aria-hidden="true" >
        </span>

        <span className="absolute inset-0 h-full w-full flex items-center justify-center transition-opacity opacity-100 ease-in duration-200" aria-hidden="true" >
        </span>
    </span>
</button>
}
export {SwitchLabel};
export default Switch;