import React, { useState } from "react"
import { Link } from "react-router-dom";
import Document from "./svg/Document";

export const NavGroup = ({ children, label }) => {
    const [isCollapsed, setCollapsed] = useState(false);
    let height = 36;

    if(Array.isArray(children) ){
        let length = children.filter(c=>c).length
        height=36*length;

        height+=4*(length-1);
    }

    function style()  {
        return {
            transitionProperty: "height margin-top",
            transitionDuration: ".2s",
            transitionTimingFunction: "cubic-bezier(0.4, 0, 0.2, 1)",
            overflow: "hidden",
            height: isCollapsed?"0px":`${height}px`,
            
        }
    }



    
    const toggleStyle = () => {
       

        setCollapsed(!isCollapsed);
    }
   

    return <>
    {
            label &&
            <button onClick={()=>toggleStyle()} className="text-gray-300 flex items-center justify-between w-full">
                <p className="font-bold uppercase  text-xs tracking-wider">
                    {label}
                </p>
                <svg x-show="$store.sidebar.groupIsCollapsed(label)" className="w-3 h-3 " xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7"></path>
                </svg>
            </button>
        }
        <ul className={`text-sm space-y-1 -mx-3  ${isCollapsed?"":(label&&'mt-2')} ` } style={style()}>
                {children}
            </ul>
            </>
}



export const NavItem = ({ children, icon, href, id, active, absolute }) => {

    icon??=Document;

    var className = "flex items-center gap-3 px-3 py-2 rounded-lg font-medium transition hover:bg-gray-300/5 focus:bg-gray-300/5";

    if(active){
        className="flex items-center gap-3 px-3 py-2 rounded-lg font-medium transition bg-primary-500 text-white";
    }

    const A = absolute? ({children, to, className})=><a href={to} className={className}>
        {children}
    </a>:({children, to, className})=><Link to={"../"+to} className={className}>
        {children}
    </Link>
    
    return <li id={id} key={id}>
        <A to={href} className={className}>
            
        {icon({className: "w-5 h-5"})}
        <span>
            {children}
        </span>
    </A>
    </li>
   
}

export const Navigation = ({ children }) => {
   
    return <nav className="flex-1 overflow-y-auto py-6">
        <ul className="space-y-6 px-6">
            {children.map((c, index) => {
                return <li className="after:border-t after:border-dark-300 after:-mr-3 after:block after:mt-6 last:after:hidden" key={index}>
                {c}
            </li>
                
            })}
            
        </ul>
    </nav>
}

export default Navigation;
