const Label = ({children, htmlFor}) => {
    return <div className="flex items-center justify-between space-x-2 rtl:space-x-reverse">
    <label className="inline-flex items-center space-x-3 rtl:space-x-reverse" htmlFor={htmlFor}>


        <span className="text-sm font-medium leading-4 text-gray-200">
            {children}

        </span>


    </label>

</div>
}
const BoldLabel = ({children, htmlFor}) => {
    return <label htmlFor={htmlFor} className="block text-sm font-bold text-gray-200 mb-2">{children}</label>
}

export {BoldLabel}
export default Label;