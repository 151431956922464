import React, { useEffect, useState } from "react";
import Label from "./form/Label";
import Popup from "./Popup";
import DatePicker from "./form/DatePicker";

const Icon = ({selectedFilters}) => {
    return <>
    <svg className="w-5 h-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 4a1 1 0 011-1h16a1 1 0 011 1v2.586a1 1 0 01-.293.707l-6.414 6.414a1 1 0 00-.293.707V17l-4 4v-6.586a1 1 0 00-.293-.707L3.293 7.293A1 1 0 013 6.586V4z"></path>
    </svg>
    {
        selectedFilters>0&&<div className="absolute start-full top-0 z-[1] -ms-1 -translate-x-1/2 rounded-md rtl:translate-x-1/2 bg-dark-900">
            <div className=" flex items-center justify-center gap-x-1 whitespace-nowrap rounded-md  text-xs font-medium ring-1 ring-inset px-0.5 min-w-[theme(spacing.4)] tracking-tighter bg-primary-400/10  text-primary-400 ring-primary-400/30">
                {selectedFilters}
            </div>

        </div>
    }
    </>
}

const DateRangeFilter = ({onChange, value}) => {
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);

    useEffect(()=>{
        if(!value?.type==="dateRange"){
            onChange({
                type:"dateRange",
                startDate:value?.startDate,
                endDate:value?.endDate
            });
        }
        setStartDate(value?.startDate??null);
        setEndDate(value?.endDate??null);
    }, [value])
  
    const updateStartDate = (date)=>{
        setStartDate(date);
        updateDate(date,endDate);
    }
    const updateEndDate = (date)=>{
        setEndDate(date);
        updateDate(startDate,date);
    }
    
    const updateDate = (sDate, eDate)=>{
        onChange({
            type:"dateRange",
            startDate:sDate,
            endDate:eDate
        });
    }
    return <>
    <DatePicker value={startDate} onChange={updateStartDate} max={endDate}/>
    <DatePicker value={endDate} onChange={updateEndDate} min={startDate}/>
    </>
}



class Filter extends React.Component {

    constructor(props) {
        super(props);

        
        this.state = {
            shouldUpdate: false,
            values: {

            }
        }
        if(this.props.searchParams){
            this.getItem = (key)=>this.props.searchParams[0].get(`filters.${key}`);
            this.setItem = (key,value)=>this.props.searchParams[0].set(`filters.${key}`, value);
            this.removeItem = (key)=>this.props.searchParams[0].delete(`filters.${key}`);
            
        } else {
            this.getItem = (key,pathname)=>sessionStorage.getItem(`filters.${pathname}.${key}`);
            this.setItem = (key,value,pathname)=>sessionStorage.setItem(`filters.${pathname}.${key}`, value);
        }
    }
    
    getCurrentPathName = () =>document.location.pathname;
    
    
    

    componentDidMount(){
        var state = {

        }

        
        const pathname = this.getCurrentPathName();
        if(!this.props.searchParams){
            this.props.filters.forEach(element => {
                var jsonValue = this.getItem(element.name, pathname)
                if(!jsonValue){
                    jsonValue=JSON.stringify(element.defaultValue);
                    this.setItem(element.name, jsonValue,pathname);
                    state[element.name]=  element.defaultValue;
                    
                }
                else{
                    state[element.name]=  JSON.parse(jsonValue);
    
                }
                
            });
        } else{
            this.props.filters.forEach(element => {
                var jsonValue = this.getItem(element.name, pathname)
                if(!jsonValue){
                    jsonValue=JSON.stringify(element.defaultValue);
                    state[element.name]=  element.defaultValue;
                    
                }
                else{
                    state[element.name]=  JSON.parse(jsonValue);
    
                }
                
            });
        }

        

        this.setState({
            values: state,
            shouldUpdate: true
        });

    }
    componentDidUpdate(){
        if(this.state.shouldUpdate){
            this.setState({shouldUpdate: false});
            this.props.onChange(this.state.values);
            if(this.props.searchParams){
                this.props.searchParams[1](this.props.searchParams[0]);
            }
        }
    }

    resetFilters = () => {
        var state = {

        }
        const pathname = this.getCurrentPathName();

        if(this.props.searchParams){
            this.props.filters.forEach(element => {
                state[element.name]=element.defaultValue;
                this.removeItem(element.name)
    
            });
        } else{
            this.props.filters.forEach(element => {
                state[element.name]=element.defaultValue;
                this.setItem(element.name,JSON.stringify(element.defaultValue),pathname)
    
            });
        }

        this.setState({
            values: state,
            shouldUpdate: true
        });
    }

    Control = ({name, label, element})=>{
        const setValue = (value) =>{
            const pathname = this.getCurrentPathName();
            let newState = this.state.values;

            newState[name]=value;
            this.setState({
                shouldUpdate: true,
                values: newState 
            });

            this.setItem(name, JSON.stringify(value), pathname);

        }

        return <div className="col-span-1">
        <div className="grid gap-6 grid-cols-1">
            <div className="col-span-1 ">
                <div>
                    <div className="space-y-2">
                        <Label htmlFor={`filter.${name}`}>
                            {label}
                        </Label>
                        {
                            React.cloneElement(element, {
                                name: `filter.${name}`,
                                onChange:setValue,
                                value: this.state.values[name]
                            })
                        }
                    </div>
                </div>
            </div>
        </div>
    </div>
    }
    getSelectedFilters = () =>Object.values(this.state.values).filter(element => {
        if(Array.isArray(element)){
            return element.length>0;
        } else if(element?.type==="dateRange"){
            return element.startDate||element.endDate;
        } else if(element?.type==="range"){
            return element.min||element.max;
        }
        else{
            return element;
        }
    }).length;

    render(){
        
        return <Popup icon={<Icon selectedFilters={this.getSelectedFilters()} />} align="left" className="right-0 rtl:right-auto rtl:left-0 z-10 w-screen pl-12 rtl:pr-12 top-full max-w-sm">
        <div className="px-6 py-4 bg-dark-600 space-y-6 shadow-xl rounded-xl">
            <div className="grid gap-6 grid-cols-1 lg:grid-cols-1">
                {
                    this.props.filters.map((filter, index)=><React.Fragment key={index}>{this.Control(filter)}</React.Fragment>)
                }
                
            </div>
            <div className="text-right">
                <button type="button" className="hover:underline focus:outline-none focus:underline  text-danger-600 hover:text-danger-500 text-sm font-medium" onClick={this.resetFilters}>
        Reset filters
    </button>
            </div>
        </div>
    </Popup>
    }
}



export {DateRangeFilter}
export default Filter;