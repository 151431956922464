const jPath = (obj, path) => {
    
    let value = obj;

    if(!value||!path){
        return value;
    }

    path.split(".").every((jpath)=>{
        value=value[jpath];
        return value;
    })
    
    return value;
}
const jPathSet = (obj, path, newValue)=>{

    const rex = /([^\[]+)\[(\d+)\]/;
    let value = obj;
    if(!value||!path){
        return value;
    }
    
    let pathArray =path.split(".");
    
    pathArray.every((jpath, index)=>{
        let match =rex.exec(jpath);
        if(match){
            let property =match[1];
            let arrayIndex = parseInt(match[2]);
            
            value=value[property]??=[];
            
            
            if(index===pathArray.length-1){
                value[arrayIndex]=newValue;
            } else{
                value=value[arrayIndex]??={};
            }

        } else{
            if(index===pathArray.length-1){
                value[jpath]=newValue;
            } else{
                
                value=value[jpath]??={};
            }

        }
        if(jpath.startsWith("links")){
        }

        
        
        return value;
    })

}

export {jPathSet};
export default jPath;