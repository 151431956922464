import { useEffect, useState } from "react";
import useAuth from "../hooks/useAuth";
import Link from "../components/svg/Link";
import useLog from "../hooks/useLog";

const Calls = () =>{
    const [userInfo, checkAuth] = useAuth();
    const [embedCode, setEmbedCode] = useState(<></>);
    const [] = useLog("Dashboard", "Calls");

    useEffect(()=>{
        if(userInfo?.customer?.dashboard_calls_url){
            let code = <>
            <div className="overflow-hidden " style={{height:"870px", width:"100%"}}>
            <iframe className="looker-embed -translate-x-[10%] lg:-translate-y-[7%] xl:-translate-y-[10%] -translate-y-[10%] z-[-10]" style={{height:"1080px", width:"125%"}} src={userInfo.customer.dashboard_calls_url.replace(/(u\/0\/)?reporting/, "embed/reporting")}  allowFullScreen></iframe>
            </div>
            </>;
            setEmbedCode(code);
        }
    }, [userInfo]);

    return <div className="flex flex-col-reverse">
        {embedCode}
        
    </div>
}

export default Calls;