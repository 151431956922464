import { useEffect, useState } from "react"
import Range from "./form/Range";

const RangeFilter = ({min,max,value,onChange}) => {
    const [minNumber, setMinNumber] = useState();
    const [maxNumber, setMaxNumber] = useState();
    useEffect(()=>{
        setMinNumber(value?.min);
        setMaxNumber(value?.max);
        
    }, [value])
    const handleChange = (mn,mx)=>{
        onChange({type:"range",min:mn, max:mx});
        setMinNumber(mn);
        setMaxNumber(mx);
    }

    return <Range min={min} max={max} minValue={minNumber} maxValue={maxNumber} onChange={handleChange}/>;
}

export default RangeFilter;