import { useEffect, useRef, useState } from "react";
import axiosClient from "../axiosClient";
import useData from "../hooks/useData";
import Pencil from "../components/svg/Pencil";
import LinkSvg from "../components/svg/Link" ;
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";

const Links = () =>{
    const [links, refreshLinks, isLoading] = useData([], "links");
    const [editingTag, setEditingTag] = useState();
    const [tagValue, setTagValue] = useState();
    const inputRef = useRef();

  

    const startEdit = (tag)=>{
        setEditingTag(tag);
        setTagValue(tag);
        setTimeout(()=>{
            inputRef.current.select();
        },0)
    }
    const finishEdit = () => {
        axiosClient({
            url:"links/tags/rename",
            method:"POST",
            data:{
                newTagName:tagValue,
                oldTagName:editingTag
            }
        }).then(()=>{
            refreshLinks();
            setEditingTag(null);
        })
    }
    const handleEnter = (e)=>{
        if(e.code==="Enter"){
            inputRef.current.blur();
        }
    }

    return <div className="divide-y divide-dark-300">
        {
            links.map((link,index)=>{
                let childLinks = link.links.$values;

                return <div key={index} className="space-y-6 py-12">
                    <div className="group flex space-x-6 min-h-[1rem]">
                    {
                        editingTag===link.tag?
                        <>
                            <input onBlur={finishEdit} ref={inputRef} onKeyDown={handleEnter} value={tagValue} onChange={e=>setTagValue(e.target.value)} className="border-0 text-lg text-gray-300 bg-transparent outline-none"/>
                        </>
                        :<>
                        {
                        link.tag&&<h3 className="text-lg text-gray-300">
                        {link.tag}
                    </h3>
                    }
                    <button className="hidden group-hover:block" onClick={e=>startEdit(link.tag)}>
                        <Pencil className="h-4 w-4 opacity-100 transition-opacity hover:opacity-60"/>
                    </button>
                    </>
                    }
                    
                    
                        </div>
                        <ResponsiveMasonry columnsCountBreakPoints={{768:2, 0:1, 1280:3}}>
                    <Masonry  gutter="1.5rem">
                    {childLinks.map((link,index)=><Link key={index} id={link.id} title={link.title} description={link.description} url={link.url}/>)}
                </Masonry>
                    </ResponsiveMasonry>
                </div>
            })
            
        }   
    </div>
}

const Link = ({title, description, url, id}) => {
        
        return <div className="col-span-1 bg-dark-600 border border-dark-300 rounded-lg shadow group overflow-hidden flex flex-row-reverse">
            <a href={`${url?.match(/^https?:\/\//)?'':'https://'}${url}`} target="_blank" className="w-full h-full">
            <div className="w-full flex items-center p-6 space-x-6 ">
                        <div className="flex-1   w-full overflow-hidden">
                            <div className="flex items-center space-x-3">
                                <h3 className="text-gray-50 text-sm font-medium group-hover:opacity-60 group-hover:text-gray-200 transition text-ellipsis overflow-hidden">{title}</h3>

                            </div>
                            <p className="mt-1 text-gray-200 text-sm group-hover:text-gray-300 group-hover:opacity-60 transition">{description}</p>
                        </div>
                        {
            id?<a href={`/links/${id}/edit`} className="hidden group-hover:flex">
            <Pencil className="h-4 w-4 opacity-100 transition-opacity hover:opacity-60"></Pencil>
        </a>:<></>
        }
                        <LinkSvg className="h-4 w-4 transition-opacity hover:opacity-60"/>
                        
                    </div>
                    
         
        </a>
        
        </div>
}

export default Links;