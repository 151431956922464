import { useEffect, useState } from "react";

const FadeIn = ({children, className, visible, time}) => {
    const [state, setState] = useState(0)
    const [cSuffix, setCSuffix] = useState("hidden opacity-0")
    time??=150;

    useEffect(()=>{
        
        setState(visible?2:0);
    }, [])

    useEffect(()=>{
        if(visible){
            if(state===0){
                setCSuffix("opacity-0");
                setState(1);
            } else if(state===1){
                setTimeout(()=>{
                    setCSuffix("opacity-100");
                }, 10)
                
                setTimeout(()=>{
                    setState(2);
                }, 10+time);
            }
            
        } else{
            if(state===2){
                setCSuffix("opacity-0");
                setState(1);
                setTimeout(()=>{
                    setState(0);
                }, time)
            }
            else if(state===0){
                setCSuffix("hidden opacity-0");

            }
        }
    }, [visible, state])

    return <div className={`${className} ${cSuffix} transition-opacity`} style={{transitionDuration:time}}>
        {children}
    </div>
}
export default FadeIn;